import React from 'react';
import { Container, Grid, Paper, Typography, Avatar, ThemeProvider, createTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const theme = createTheme({
  spacing: 4,
  palette: {
    background: {
      default: '#C9B8F9',
    },
    text: {
      primary: '#000',
      secondary: '#555',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '32px',
          backgroundColor: '#f5f5f5',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px',
          textAlign: 'center',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '56px',
          height: '56px',
          margin: 'auto',
        },
      },
    },
  },
});

const testimonials = [
  {
    name: 'Saneil S. (Founder)',
    avatar: 'https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id%3D9919991e-8091-70df-8d4f-eb99e3037337/images/testimony3.jpg',
    text: 'I haven\'t added user reviews yet. Listen to the samples and make your own decision. Don\'t be a sheep.',
  },
  {
    name: 'Saneil S. (Founder)',
    avatar: 'https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id%3D9919991e-8091-70df-8d4f-eb99e3037337/images/testimony5.jpg',
    text: 'Testimonials are 90% enthusiasm, 10% reality. Don\'t trust the hype. Real products don\'t need fake praise.',
  },
  {
    name: 'Saneil S. (Founder)',
    avatar: 'https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id%3D9919991e-8091-70df-8d4f-eb99e3037337/images/testimony1.jpg',
    text: 'My Guarantee: I\'ll refund or recreate your first podcast if you don\'t like it!',
  },
];

const TestimonialContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
}));

const TestimonialPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const TestimonialAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  margin: 'auto',
}));

const TestimonialText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Testimonials = () => {
  return (
    <ThemeProvider theme={theme}>
      <TestimonialContainer>
        <Typography variant="h4" component="h2" gutterBottom>
          What Our Users Say
        </Typography>
        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={4} key={index}>
              <TestimonialPaper>
              <TestimonialText variant="body1">
                  "{testimonial.text}"
                </TestimonialText>
                <TestimonialAvatar alt={testimonial.name} src={testimonial.avatar} />
                <Typography variant="h6" component="h3">
                  - {testimonial.name}
                </Typography>
                
              </TestimonialPaper>
            </Grid>
          ))}
        </Grid>
      </TestimonialContainer>
    </ThemeProvider>
  );
};

export default Testimonials;
