import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './styles.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import ReactGA from 'react-ga4';
import Home from './views/home';
import SignInForm from './views/SignInForm';
import Register from './views/Register';
import Verification from './views/Verification';
import SimpleStart from './views/SimpleStart';
import PodOutline from './views/PodOutline';
import PodPlayer from './views/PodPlayer';
import PodPlayerV2 from './views/PodPlayerV2';
// import Episodes from './views/Episodes';
import ProtectedRoute from './views/ProtectedRoute';
import Landing from './views/Landing';
import { UserProvider } from './components/UserContext';
// import { loadStripe } from '@stripe/stripe-js';
import PrivacyPolicy from './views/PrivacyPolicy';
import Onboarding from './views/Onboarding';
import TermsOfService from './views/TermsOfService';
import Transcript from './views/Transcript';
import PotionsDisplay from './potions/PotionsDisplay';
import { ThemeProvider } from '@mui/material/styles';
import muiTheme from './general/muiTheme';
import PlayerMain from './player/PlayerMain';
import Dashboard from './views/Dashboard';
import PrashantPage from './views/PrashantPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID,
  }
});

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? 'G-7KJHHFBX8S');


root.render(
  <ThemeProvider theme={muiTheme}>
    <UserProvider>
      <Router>
        <Routes>

          <Route path="/" element={<Landing />} />
          <Route path="landing" element={<Landing />} />
          <Route path="/login" element={<SignInForm />} />
          {/* <Route path="/onboarding" element={<Onboarding />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/outline" element={<PodOutline />} />
          {/* <Route path="/episodes" element={<Episodes/>} /> */}
          <Route path="/verification" element={<Verification />} />
          <Route path="/start" element={<ProtectedRoute element={<SimpleStart passthrough="/start" />} source="/register" />} />
          <Route path="/podplayer" element={<PodPlayerV2 />} />
          <Route path="/podplayerv2" element={<PodPlayerV2 />} />
          <Route path="/potions" element={<PotionsDisplay />} />
          {/* <Route path="/playerv3" element={<PlayerMain />} /> */}
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/transcript" element={<Transcript />} />
          <Route path="/terms" element={<TermsOfService />} />
          {/* <Route path="/dashboard" element ={<Dashboard/>} /> */}
          <Route path="/founder_firestarters" element ={<PrashantPage/>} />
          {/* <Route path="/home" element={<Landing/>} />
        <Route path="/aivsai" element={<CreateConvo/>} />
        <Route path="/conversation" element={<ProtectedRoute  element={<Conversation passthrough="/conversation"/>} />} />
        <Route path="/signupemail" element={<EmailList/>} />
        <Route path="/error-page" element={<ErrorBoundary/>} />
        <Route path="/pricing" element={<StripeParent/>} />
        <Route path="/signin" element={<CustomSignIn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<Terms />} />
        <Route path="/curiosityquest" element={<CuriosityQuest />} />
        <Route path="/courthouse" element={<Courthouse />} />  
        <Route path="/account" element={<ProtectedRoute  element={<UserProfile passthrough="/account"/>} />} />        */}

        </Routes>
        {/* </main> */}
      </Router>
    </UserProvider>
  </ThemeProvider>
);



reportWebVitals();
