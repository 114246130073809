import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useCustomNavigate } from '../general/functions';

import './SignInForms.css';

const Register = () => {
    const [searchParams] = useSearchParams();
    const navigateWithParams = useCustomNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [error, setError] = useState(null);
    const protected_path = decodeURIComponent(searchParams.get('protected_path'))
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [emailUpdates, setEmailUpdates] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleEmailUpdatesChange = () => {
        setEmailUpdates(!emailUpdates);
    };

    const handleSignup = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        if (email === '') {
            setError("Please enter your email");
            return;
        } else if (password === '') {
            setError("Please enter a password");
            return;

        } else if (!termsAgreed) {
            console.log('termsAgreed', termsAgreed)
            setError("Please agree to Terms of Service and Privacy Policy");
            return;

        }

        setIsSubmitting(true);

        try {
            setError(null);
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    'email': email,
                    'custom:email_updates': `${emailUpdates}`
                }

            });
            navigateWithParams('/verification', { 'email': email, 'register':'true', 'protected_path': protected_path });
        } catch (err) {
            setError(err.message);
        } finally {
            setIsSubmitting(false); // Re-enable the form
        }

    };

    return (
        <div className="sign-in-container">

            <div className="logo-container">
                {/* Replace 'logo.png' with your actual logo path */}
                {/* <img src="logo.png" alt="Logo" className="logo"/> */}
            </div>
            <div className="welcome-container">
                <h2>Pod Potion</h2>
                <p>Welcome to Pod Potion</p>
            </div>
            <form onSubmit={handleSignup} className="sign-in-form">

                <div className="input-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className='password-requirements-container'>
                    Password Requirements
                    <span className='password-requirements-requirement'>✔ 8+ characters</span>
                    <span className='password-requirements-requirement'>✔ 1+ number</span>
                    <span className='password-requirements-requirement'>✔ 1+ special character</span>
                    <span className='password-requirements-requirement'>✔ 1+ uppercase letter</span>
                    <span className='password-requirements-requirement'>✔ 1+ lowercase letter</span>

                </div>
                <div className="registration-agreement-container">
                    <div className='registration-checkbox-container'>
                        <input
                            type="checkbox"
                            id="registration-termsAgreement"
                            name="registration-termsAgreement"
                            className="registration-agreement-checkbox"
                            checked={termsAgreed}
                            onChange={() => setTermsAgreed(!termsAgreed)}
                            disabled={isSubmitting} 
                        />
                        <label htmlFor="registration-termsAgreement" className="registration-agreement-label">
                            I am 18+ years old, and I agree to the <a className="registration-signin-links" href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a className="registration-signin-links" href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> *
                        </label>
                    </div>
                    <div className='registration-checkbox-container'>
                        <input
                            type="checkbox"
                            id="registration-emailUpdates"
                            name="registration-emailUpdates"
                            className="registration-agreement-checkbox"
                            checked={emailUpdates}
                            onChange={() => setEmailUpdates(!emailUpdates)}
                            disabled={isSubmitting} 
                        />
                        <label htmlFor="registration-emailUpdates" className="registration-agreement-label">
                            Send me occasional product updates from Pod Potion
                        </label>
                    </div>
                </div>
                {error && <div className='login-error'>{error}</div>}
                <button type="submit" className="sign-in-button"> {isSubmitting ? 'Signing Up...' : 'Register'}</button>
            </form>

            <div className="account-exists">
                Returning User? <a href="/login">Login</a>
            </div>
        </div>
    );
};

export default Register;
