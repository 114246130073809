// HeroSection.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const PotionsHero = () => {
    return (
        <Box sx={{
            backgroundColor: `#C9B8F9`,
            backgroundSize: 'cover',
            color: '#fff',
            padding: '100px 20px',
            textAlign: 'center'
        }}> 
            <Typography variant="h1">Listen to Sample Pod Potions</Typography>
            <Typography variant="h3">See what's possible with Pod Potion, hear un-edited samples, see the prompts that generated them, get inspired.</Typography>
            {/* <Button variant="contained" color="primary">Listen Now</Button> */}
        </Box>
    );
};

export default PotionsHero;
