import React, {useState, useContext} from 'react';
import { Avatar, Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContext } from './GetUser';


const profileTheme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5'
        },
        secondary: {
            main: '#f50057'
        }
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h5: {
            fontWeight: 500
        }
    }
});

const ProfileSection = () => {
    const { user, profile, loading, error } = useContext(UserContext);
    const [email, setEmail] = useState(profile?.email);

    return (
        <ThemeProvider theme={profileTheme}>
            <Container>
                <Typography variant="h5" gutterBottom>
                    Profile Overview
                </Typography>
                <Box display="flex" alignItems="center" mb={2}>
                    <Avatar sx={{ width: 56, height: 56, mr: 2 }} />
                    <Typography variant="h6">{email}</Typography>
                </Box>
                {/* <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Email" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Password" type="password" fullWidth />
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button variant="contained" color="primary">
                        Save Changes
                    </Button>
                </Box> */}
            </Container>
        </ThemeProvider>
    )
};

export default ProfileSection;
