import React, { useState, useEffect } from 'react';
import { createPodPotionConfig, useCustomNavigate, checkUserAuthentication } from '../general/functions';
import { useUser } from '../components/UserContext';
import CostCalculator from '../components/CostCalculator';
import './SimpleStart.css';

const SimpleStart = () => {
    const [inputValue, setInputValue] = useState('');
    const [user, setUserA] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [episodes, setEpisodes] = useState(3);
    const [minutes, setMinutes] = useState(10);
    const navigateWithParams = useCustomNavigate();
    const { setUser } = useUser();

    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        checkUserAuthentication(setUserA);

    }, []);


    const handleEdit = () => {
        setEditMode(true);
    };
    // async function handleSignIn(username, password) {
    //     setIsSubmitting(true);
    //     try {
    //       const user = await Auth.signIn(username, password);
    //       console.log(user);
    //       // User is signed in
    //     } catch (error) {
    //       console.log('Error signing in', error);
    //       // Handle sign in errors
    //     }
    //     setIsSubmitting(false);
    //   }

    //   async function handleCheckUser() {
    //     try {
    //       const user = await Auth.currentAuthenticatedUser();
    //       console.log(user);
    //       // User is signed in
    //     } catch {
    //       console.log('Not signed in');
    //       // User is not signed in
    //     }
    //   }

    const handleChange = (event) => {
        setInputValue(event.target.value); // Update the state with the new value of the textarea
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (inputValue === '')
            {
                return;
            }
        setSubmitting(true);
        await new Promise(resolve => setTimeout(resolve, 0));
        const response = await createPodPotionConfig(inputValue, episodes, minutes, user?.signInUserSession?.accessToken?.jwtToken);
        setUser(user);
        if (response?.PK) {
            navigateWithParams('/podplayerv2', { 'id': response?.PK });
        }
        setSubmitting(false);

    };

    return (
        <div className="start-page-container">
            <div className="start-input-container">

                <h1 className="start-input-title">Enter your Podcast Prompt</h1>
                <div className="start-textarea-button-container">
                    <textarea
                        id="start-topic-input"
                        placeholder="Create a podcast about blue whales..."
                        value={inputValue}
                        onChange={handleChange}
                        required
                        disabled={submitting}
                    ></textarea>

                    {!editMode ? (
                        <div className='edit-episodes-container'>
                            <div className="edit-episodes-info">
                                <span className="edit-episodes-info-span" >No. of Episodes: {episodes}</span>
                                <button className="buttonFlat" onClick={setEditMode}>Edit</button>
                            </div>
                        </div>
                    ) : (
                        <CostCalculator setMinutesPerEpisode={setMinutes} setNumEpisodes={setEpisodes} numEpisodes={episodes} minutesPerEpisode={minutes} disabled={submitting} />
                    )}


                    <button type="button" className="start-input-button" onClick={handleSubmit} disabled={submitting}>
                    {submitting ? 'Generating...' : 'Generate Free Preview'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SimpleStart;
