import { createTheme } from '@mui/material/styles';

const muiTheme = createTheme({
  palette: {
    primary: {
      light: '#6466A8', // primary-300
      main: '#6466A8', // primary-500
      dark: '#222684', // primary-700
      contrastText: '#FFFFFF',
    },
    primary2: {
      light: '#000000',
      main: '#FFFFFF',
      dark: '#000000',
      contrastText: '#000000',
    },
    secondary: {
      light: '#6466A8', // custom-primary2
      main: '#222684', // custom-primary1
      dark: '#080A45', // custom-neutral-dark
      contrastText: '#FFFFFF',
    },
    error: {
      light: '#E14747', // danger-700
      main: '#BF2626', // danger-500
      dark: '#A22020', // danger-300
    },
    success: {
      light: '#4CC366', // success-700
      main: '#32A94C', // success-500
      dark: '#199033', // success-300
    },
    grey: {
      500: '#595959',
      700: '#999999',
      900: '#D9D9D9',
      A100: '#000000', // gray-black
      A200: '#FFFFFF', // gray-white
    },
    customAccent: {
      main: '#C9B8F9',
    },
    background: {
      default: '#FFFFFF',
      paper: '#E5D2CE',
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Bricolage Grotesque, Arial, sans-serif',
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    potionscardTitle: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
      textAlign: 'left',
    },
    potionscardDescription: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      width: '100%',
      display: 'block',
      textAlign: 'left',
    },

    h1: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1.5,
      textAlign: 'center',
    },
    h2: {
      fontSize: 35,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.5,
      textAlign: 'center',
    },
    prompt: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
      textAlign: 'justify',
      fontStyle: 'italic',
      padding: '16px',
      paddingTop: '0px',
    },
    promptSubtitle: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.5,
      textAlign: 'left',
      padding: '16px',
      paddingBottom: '0px',
    },
    overline: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      textAlign: 'left',
    },
    button: {
      fontWeight: 'bold',
    },
    footer1: {
      padding: '20px',
    },
  },
  spacing: 8, // base spacing unit
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
          borderRadius: '4px',
          fontSize: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          paddingTop: '16px',
          backgroundColor: '#FFF',
          color: '#000',
          textAlign: 'center',
        },
      },
    },
  },

});

export default muiTheme;
