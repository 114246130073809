// useDynamicBackground.js - Custom Hook
import { useEffect, useState } from 'react';

const useDynamicBackground = () => {
    const [background, setBackground] = useState('default.jpg');

    useEffect(() => {
        const hour = new Date().getHours();
        const background = hour < 12 ? 'morning.jpg' : hour < 18 ? 'afternoon.jpg' : 'night.jpg';
        setBackground(background);
    }, []);

    return background;
};

export default useDynamicBackground;
