import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import PotionsCard from './PotionsCard';
import { styled } from '@mui/material/styles';
import PotionsHero from './PotionsHero';
import useDynamicBackground from './PotionsDynamicBackground';
import Navigation from '../components/Navigation';
import SidePanel from './PotionsSidePanel';
import PotionsFooter from './PotionsFooter';

// import { carouselContent } from '../general/samplesLandingCarousel';

const Item = styled('div')(({ theme }) => ({
    height: '100%',
}));

const PotionsDisplay = () => {
    const [podcasts, setPodcasts] = useState([]);
    const background = useDynamicBackground();
    const [featuredPodcast, setFeaturedPodcast] = useState([]);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [selectedPodcast, setSelectedPodcast] = useState(null);
    // const carouselContent = require(`${process.env.PUBLIC_URL}/samplePotions.json`);

    const handleOpenSidePanel = (podcast) => {
        window.open(`https://${process.env.REACT_APP_DOMAIN}/podplayerv2?id=${podcast?.PK}`, '_blank', 'noopener,noreferrer');
        // setSelectedPodcast(podcast);
        // setIsSidePanelOpen(true);
    };

    const handleCloseSidePanel = () => {
        setIsSidePanelOpen(false);
    };

    useEffect(() => {
        fetch('/samplePotions.json')
          .then(response => response.json())
          .then(jsonData => setPodcasts(jsonData))
          .then(jsonData => setFeaturedPodcast(jsonData[0]))
          .catch(error => console.error('Error fetching data:', error));
        // setPodcasts(carouselContent);
        // setFeaturedPodcast(carouselContent[0])

    }, []);


    return (
        <>
            <Navigation />
            <Box sx={{ flexGrow: 1, padding: 3, backgroundImage: `url(${background})` }}>

                <Container maxWidth="lg">
                    {featuredPodcast && <PotionsHero featuredPodcast={featuredPodcast} />}
                    <Grid container spacing={3}>
                        {podcasts.map((podcast, index) => (
                            <Grid key={index} xs={12} sm={6} md={4} lg={4} xl={4}>
                                <Item>
                                    <PotionsCard
                                        imageLink={podcast.image_url}
                                        title={podcast.title}
                                        description={podcast.description}
                                        prompt={podcast.prompt}
                                        pk={podcast.pk}
                                        num_episodes={podcast.num_episodes}
                                        onClick={() => handleOpenSidePanel(podcast)}
                                    />
                                </Item>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <SidePanel
                podcast={selectedPodcast}
                open={isSidePanelOpen}
                onClose={handleCloseSidePanel}
            />
            <PotionsFooter />
        </>
    );
};

export default PotionsDisplay;
// import Grid from '@mui/material/Grid';
// import HeroSection from './HeroSection';
// import useDynamicBackground from './useDynamicBackground';

// const PotionsDisplay = () => {
//     const background = useDynamicBackground();
//     const featuredPodcast = podcasts[0]; // Assume the first podcast is featured for simplicity
//     useEffect(() => {
//         const fetchPodcasts = async () => {
//             try {
//               // const response = await axios.get(apiUrl);
//               const response =[
//                   {
//                       "id": 101,
//                       "picture": "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/courthouse-poly.png",
//                       "title": "Rise & Shine: Morning Motivation",
//                       "description": "Start your day with actionable tips and uplifting stories to set a positive tone for the day.",
//                       "category": "Motivation",
//                       "length_min": "20",
//                       "episode_length": "30",
//                       "purchase_status": true,
//                       "credits": 100,
//                       "internal_status": "published",
//                       "cost": 10,
//                       "is_public": true,
//                       "user_id": "f54a51f3-3935-42df-b25b-234d8abcdc57",
//                       "PK": "101",
//                       "SK": "episode101"
//                   },
//                   {
//                       "id": 102,
//                       "picture": "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/conversations-poly.png",
//                       "title": "Tech Trends",
//                       "description": "Dive deep into the latest in technology, from AI innovations to the future of telecommunications.",
//                       "category": "Technology",
//                       "length_min": "45",
//                       "episode_length": "12",
//                       "purchase_status": false,
//                       "credits": 200,
//                       "internal_status": "pending",
//                       "cost": 15,
//                       "is_public": false,
//                       "user_id": "c28a33d1-987b-48d5-b56d-5e8c1e48d3e8",
//                       "PK": "102",
//                       "SK": "episode102"
//                   },
//                   {
//                       "id": 103,
//                       "picture": "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/curiosity-poly.png",
//                       "title": "Echoes of History",
//                       "description": "Explore significant historical events and their impacts on our lives today. A journey through time with expert guests.",
//                       "category": "History",
//                       "length_min": "60",
//                       "episode_length": "20",
//                       "purchase_status": true,
//                       "credits": 300,
//                       "internal_status": "published",
//                       "cost": 20,
//                       "is_public": true,
//                       "user_id": "aad35f1c-93e1-4f8c-a8bb-8760e72b317b",
//                       "PK": "103",
//                       "SK": "episode103"
//                   },
//                   {
//                       "id": 103,
//                       "picture": "https://static-funcannyai.s3.us-west-1.amazonaws.com/webapp-images/staging/curiosity-poly.png",
//                       "title": "Test title 2",
//                       "description": "Explore significant historical events and their impacts on our lives today. A journey through time with expert guests.",
//                       "category": "History",
//                       "length_min": "60",
//                       "episode_length": "20",
//                       "purchase_status": true,
//                       "credits": 300,
//                       "internal_status": "published",
//                       "cost": 20,
//                       "is_public": true,
//                       "user_id": "aad35f1c-93e1-4f8c-a8bb-8760e72b317b",
//                       "PK": "103",
//                       "SK": "episode103"
//                   }
//               ]
//               setPodcasts(response);
//             } catch (error) {
//               console.error('Failed to fetch podcasts:', error);
//             }
//           };
//         fetchPodcasts();
//       }, []);
//     return (
//         <Box sx={{ flexGrow: 1, padding: 3, backgroundImage: `url(${background})` }}>
//             <HeroSection featuredPodcast={featuredPodcast} />
//             <Grid container spacing={3}>
//                 {podcasts.map((podcast, index) => (
//                     <Grid key={index} item xs={12} sm={index === 0 ? 12 : 6} md={index === 0 ? 12 : 4}>
//                         <PotionsCard podcast={podcast} />
//                     </Grid>
//                 ))}
//             </Grid>
//         </Box>
//     );
// };