import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useSearchParams } from 'react-router-dom';
import { useCustomNavigate } from '../general/functions';
import './SignInForms.css';

const Verification = () => {
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(decodeURIComponent(searchParams.get('email') || ''));
    const [code, setCode] = useState(searchParams.get('code') || '');
    const register = searchParams.get('register') === 'true' || false;
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const protected_path = decodeURIComponent(searchParams.get('protected_path')) || '/';
    const navigateWithParams = useCustomNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePasswordReset = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            setError(null);
            await Auth.forgotPasswordSubmit(email, code, newPassword);
            await Auth.signIn(email, newPassword);
            navigateWithParams(protected_path, {})
        
        } catch (err) {
            setError(err.message);
           
        } finally {
            setIsSubmitting(false); // Re-enable the form
        }
    };
    const handleEmailConfirm = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            setError(null);
            await Auth.confirmSignUp(email, code);
            navigateWithParams('/start', { 'email': email })
        } catch (err) {
            setError(err.message);
        } finally {
            setIsSubmitting(false); // Re-enable the form
        }
    };
    const handleResend = async () => {
        try {
            setError(null);
            if (register) { 
                console.log('resend register', email);
                await Auth.resendSignUp(email); }

            else { console.log('resend forgot', email);
             await Auth.forgotPassword(email); }
        } catch (err) {
            setError(err.message);
        }
    };


    return (
        <div className="sign-in-container">
            <div className="logo-container">
                {/* Replace 'logo.png' with your actual logo path */}
                {/* <img src="logo.png" alt="Logo" className="logo"/> */}
            </div>
            <div className="welcome-container">
                <h2>Confirmation Code</h2>
                <p>sent to {email}</p>

            </div>
            <form onSubmit={register ? handleEmailConfirm : handlePasswordReset} className="sign-in-form">
                <div>{register}</div>
                <div className="input-group">
                    <label htmlFor="username">Code:</label>
                    <input
                        type="text"
                        id="username"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                        disabled={isSubmitting}
                    />
                </div>
                {!register &&
                    (<div className="input-group">
                        <label htmlFor="password">New Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            disabled={isSubmitting}
                        />
                    </div>)}

                {error && <div className='login-error'>{error}</div>}
                <button type="submit" className="sign-in-button" disabled={isSubmitting}>
                    {register ? (isSubmitting ? 'Confirming...' : 'Confirm Email Address') : (isSubmitting ? 'Updating...' : 'Update Password')}
                </button>
                <div className='verification-note'> <span>Check Spam folder OR </span> <span
                    className="forgot-password"
                    onClick={handleResend}

                >
                    Resend Code
                </span></div>
            </form>
            <div className="account-exists">



            </div>

            <div className="account-exists">
                <a href="/login">Back to Login</a>
            </div>
        </div>
    );
};

export default Verification;
