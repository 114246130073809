import { useStripe, useElements, Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
import { createPaymentIntent, checkUserAuthentication2 } from '../general/functions';
import './Checkout.css';

// Move stripePromise outside of the component to avoid recreating it on every render

// const stripePromise = loadStripe('pk_test_51OqTUhGI13dLYQwWTJKCG5aghHyD0zwweSHzsR1OheP1bBnT5K0eEzHJB45sdknOVd73eDVxKLXTdw1n7plAecmm009lTYTdqv');

const Checkout = ({ episodes }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [presetupComplete, setPresetupComplete] = useState(true);
    const [user, setUser] = useState(null);
    const domain = process.env.REACT_APP_DOMAIN;
    const [options, setOptions] = useState({ clientSecret: '' });
    const totalCredits = episodes.reduce((total, episode) => total + episode.credits, 0); 
    const totalCost = episodes.reduce((total, episode) => total + episode.cost, 0);

    // useEffect(() => {
    //     const presetup = async () => {
    //         const user = await checkUserAuthentication2();
    //         // const { data } = await createPaymentIntent(user.signInUserSession.accessToken.jwtToken, totalCredits, 'test');
    //         // setOptions({ clientSecret: data['pis'] });
    //         setPresetupComplete(true);
    //         // console.log('ooptions', data['pis'])
    //     };

    //     presetup();
    // }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
            setLoading(false);
            return;
        }
        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `https://${domain}/podplayerv2?id=${episodes?.[0]?.PK}`,
            },
        });

        if (error) {
            setLoading(false);
            console.error(error.message);
        } 
    };

    return (
        <div>
            {presetupComplete ? (
                <form onSubmit={handleSubmit}>
                    <h2>Episode Summary</h2>
                    <ul>
                        {episodes.slice(1).map(episode => (
                            <li key={episode.episode_number}>
                                Ep {episode.episode_number}: {episode.credits} Credits
                            </li>
                        ))}
                    </ul>
                    <br/>
                    <p>Total Credits: {totalCredits}</p>
                    <p>Total Cost: ${totalCost}</p>
                    <br/>
                    <PaymentElement /> 
                    <button class="pricing-card-button buttonFilledSecondary" type="submit" disabled={loading} >
                        Complete Purchase (${(totalCost).toFixed(2)})
                    </button>
                    
                </form>
           
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default Checkout;
