import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getPodPotionOutline, useCustomNavigate, getPodPotionEpisodes, checkUserAuthentication} from '../general/functions';
import SchedulePicker from '../components/SchedulePicker';
import Feedback from '../components/Feedback';
import { useUser } from '../components/UserContext';   
import './PodOutline.css'

// import { createPodPotionConfig, NavigateToApp } from '../general/functions';

const PodOutline = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [episodes, setEpisodes] = useState('');
    const [user, setUser] = useState('');
    const [podcastConfig, setPodcastConfig] = useState('');
    const navigateWithParams = useCustomNavigate();
    const { userContext } = useUser();
    
    useEffect(() => {
        if (userContext) {
            setUser(userContext);
        } else {
            checkUserAuthentication(user);
        }
    }, []);
    
    useEffect(() => {
        const intervalId = setInterval(async () => {
    
        //   const response = await getPodPotionOutline(searchParams.get('id'));
        console.log('user',user, user?.signInUserSession?.accessToken?.jwtToken)
          const response = await getPodPotionEpisodes(searchParams.get('id'), user?.signInUserSession?.accessToken?.jwtToken);
          const episodes = response?.episodes
          const podcast_config= response?.podpotion_config
          if (podcast_config) {setPodcastConfig(podcast_config)}
          if (episodes?.length >0 ) {
            // setOutline(response?.episodes);
            setEpisodes(episodes)
            if (episodes?.length >1) {
                setLoading(false);
                clearInterval(intervalId);
            }
            // 
            // console.log('outline', response?.episodes)
            // setOutline(response?.episodes);
            // navigateWithParams('/episodes', {'id':response?.PK});
            // 
    
          } 
          
        console.log('poll', searchParams)
        }, 5000); // Poll every 5 seconds
    
        return () => clearInterval(intervalId); // Clean up interval on component unmount
      }, [user]);
      const EpisodesList = ({ episodes }) => {
        // Sort episodes by episode_number as an integer
        const sortedEpisodes = episodes.sort((a, b) => parseInt(a.episode_number, 10) - parseInt(b.episode_number, 10));
      
        return (
          <div>
            {sortedEpisodes.map(episode => (
              <div key={episode.episode_number} style={{ margin: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '8px' }}>
                <h2>Ep {episode.episode_number}: {episode.title}</h2>
                <p>{episode.description}</p>
                <div className='audio-player-container'>
                {episode.audio_url && 
                <div>
                  <audio className='audio-player' controls src={episode.audio_url}>
                    Your browser does not support the audio element.
                  </audio>
                  
                  </div>}
                  
                  </div>
                  {!episode.audio_url &&  <div><div>Scheduled: {episode.scheduled_date}</div><button type="submit" className="buttonFlat button">Listen Now</button></div>}
                  {episode.audio_url &&  <Feedback context={`podpotionoutline|${episode.PK}|${episode.SK}`}/>}
                  
              </div>
            ))}
          </div>
        );
      };
      
    return (
        <div className="start-page-container">
            <div className="start-input-container">
                <h1 className="start-input-title">{podcastConfig.title ? podcastConfig.title : "Brewing your Pod Potion"} </h1>
                {loading ?<div><div className="spinner-container">
                    <div className="spinner"></div>
                    
                </div><div className="wait-message"><div>Please wait while your podcast outline is being generated (This may take a few minutes).</div></div> </div>: <EpisodesList episodes={episodes} /> }
                {/* <EpisodesList episodes={outline} /> */}
            </div>
        </div>
    );
};

export default PodOutline;