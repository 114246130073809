import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

// Styling for the flip effect
const FlipContainer = styled('div')({
  height: '200px', // Adjust height as needed
  width: '100%',
  perspective: '1000px'
});

const Flipper = styled('div')(({ isFlipped }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  transition: 'transform 0.6s',
  transformStyle: 'preserve-3d',
  transform: isFlipped ? 'rotateY(180deg)' : 'none'
}));

const Front = styled('div')({
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  position: 'absolute'
});

const Back = styled('div')({
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  position: 'absolute',
  transform: 'rotateY(180deg)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff' // Set a background color to ensure readability
});
const StyledCardMedia = styled(CardMedia)({
  width: '100%', // Full width
  aspectRatio: '1 / 1', // Ensures the aspect ratio is 1:1, making it square
  objectFit: 'cover' // Ensures the image covers the square completely
});


const PotionsCard = ({ imageLink, title, description, prompt, num_episodes, pk, onClick }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  return (
    <Card onMouseEnter={() => setIsFlipped(true)} onMouseLeave={() => setIsFlipped(false)} lg={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <FlipContainer >
        <Flipper isFlipped={isFlipped}>
          <Front>
            <StyledCardMedia
              component="img"
              sx={{ width: "100%", height: "100%", objectFit: 'cover' }}
              image={imageLink}
              alt={title}
              onClick={onClick}
            />
          </Front>
          <Back>
            <div>
              <Typography variant="promptSubtitle" component="div" color="primary" >
                Prompt:
              </Typography>
              <Typography variant="prompt" component="div" color="primary">
                {prompt}
              </Typography>
            </div>
          </Back>
        </Flipper>
      </FlipContainer>
      <CardContent sx={{ width: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography gutterBottom variant="potionscardTitle" component="div">
          {title}
        </Typography>
        <Typography variant="overline" color="text.primary2">
          ({num_episodes} episode series)
        </Typography>
        <Typography variant="potionscardDescription" color="text.primary">
          {truncateText(description, 110)}
        </Typography>

      </CardContent>
      <CardActions sx={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: 2 }}>
        {/* <IconButton aria-label="play" onClick={onClick}>
          <PlayArrowIcon />
        </IconButton> */}
        <a href={`/podplayerv2?id=${pk}`}> <Button size="small">Listen Now</Button></a>
      </CardActions>
    </Card>
  );
}

export default PotionsCard;
