import React from 'react';
import './TermsOfService.css';



const TermsOfService = () => {

    const domain = process.env.REACT_APP_DOMAIN;

    const termsData = [
        {
            "title": "Introduction",
            "content": [
                "Welcome to Pod Potion! By using our website and services, you agree to these Terms of Service, which form a legal agreement between you and Pod Potion. Please read them carefully."
            ]
        },
        {
            "title": "Privacy Policy Reference",
            "content": [
                "Our Privacy Policy is an important part of these Terms. It explains how we handle your personal information. Make sure to read it to understand our practices.",
                ["link", `https://${domain}/privacy`, "Privacy Policy"]
            ]
        },
        {
            "title": "Registration and Using our services",
            "content": [
                "To ensure a positive experience for all users of Pod Potion, please adhere to the following guidelines:",
                {
                    "Age Requirement": "You must be at least 18 years old to register and use Pod Potion."
                },
                {
                    "Account Information": "Provide accurate and current information when creating your account. You are solely responsible for the security of your account and all activities under it."
                },
                {
                    "Confidentiality": "Keep your account details confidential. Immediately notify us if you detect or suspect any unauthorized use of your account."
                },
                {
                    "Legal Use": "Use Pod Potion's services in compliance with all applicable laws and regulations. Do not engage in any activity that could harm Pod Potion, its users, or its services."
                },
                {
                    "Content Respect": "Just as your content belongs to you, content created by other users or third parties belongs to them. Don't use someone else’s content without their permission. Do not distribute harmful, misleading, illegal, or copyrighted material. "
                },
                {
                    "System Integrity": "Protect the integrity of Pod Potion’s systems. Do not engage in hacking, spreading viruses, or any other actions that could compromise the security or functionality of Pod Potion or its users' data."
                }
            ]
        },

        // {
        //     "title": "Registration and Access",
        //     "content": [
        //         "To use Pod Potion, you must be at least 18 years old. When you create an account, it's important to provide accurate and current information. You are responsible for maintaining the security of your account and for all activities that occur under your account. Please keep your account details confidential and alert us immediately if you suspect any unauthorized use of your account."
        //     ]
        // },
        // {
        //     "title": "Using Our Services",
        //     "content": [
        //         "You're welcome to use Pod Potion's services, provided you stick to these Terms. Here's what you need to know:",
        //         { "Follow the Rules": "Use our services legally and don't engage in any activity that could harm Pod Potion or other users." },
        //         { "Be Respectful": " Don't use Pod Potion to spread harmful, misleading, or illegal content." },
        //         { "Stay Secure": " Don't try to harm our system. That means no hacking, spreading viruses, or doing anything that could break Pod Potion or steal data." },
        //         { "Respect Copyrights": " Don't share content you don't have the rights to." }
        //         //               Use our Services in a way that infringes, misappropriates or violates anyone’s rights.
        //         //                     Modify, copy, lease, sell or distribute any of our Services.
        //         // Attempt to or assist anyone to reverse engineer, decompile or discover the source code or underlying components of our Services, including our models, algorithms, or systems(except to the extent this restriction is prohibited by applicable law).
        //         // Automatically or programmatically extract data or Output(defined below).
        //         // Represent that Output was human - generated when it was not.
        //     ]
        // },

        {
            "title": "Content Ownership and IP",
            "content": [
                { "Input Definition": "'Input' refers to any original content you provide to Pod Potion, including but not limited to audio files, texts, images, and any other forms of media or data. You retain full ownership of your Input, affirming your rights over the content you create and share on our platform." },

                { "Output Definition": "'Output' encompasses the content generated by Pod Potion's services from your Input, such as enhanced audio, transcripts, or any derivative works. Pod Potion transfers to you any property rights it holds in the Output (except for the limited licenses described below), provided your use complies with our terms of service." },


                { "Use of Input by Pod Potion": "By using Pod Potion, you grant us a non-exclusive, worldwide, royalty-free license to interact with your Input. This is necessary to deliver our services to you, including service enhancements, bug fixes, and maintaining security. We respect your ownership and privacy and assure that our use of Input is limited to these operational purposes." },

                { "Use of Output by Pod Potion": "Similarly, you allow Pod Potion the same non-exclusive, global, royalty-free rights to use the Output as needed for service provision, improvement, and security. Our commitment to your privacy and property rights guides this usage." },
                { "Opt-In for Promotion": "For promotional activities, such as showcasing your podcasts or content, Pod Potion requires your explicit, opt-in consent. We value your trust and aim to highlight your creative successes only with your full agreement." },
                { "Model Training and External APIs": "See below section." }
            ]
        },
        {
            "title": "Model Training and External APIs",
            "content": [
                "Pod Potion leverages the advanced capabilities of External APIs AI providers like Anthropic API, OpenAI API, and others to enhance our service offerings. This integration allows us to provide you with cutting-edge features and functionalities, ensuring an innovative and efficient user experience.  We may integrate with other AI models and services in the future as well.",
                "We take data privacy very seriously. To the extent possible under the policies of the AI companies we work with, we opt out of allowing them to use your data for training their models without your explicit consent. However, when you interact with Pod Potion's services, your data may still be processed by these third-party AI services for the purpose of generating responses or completing requested tasks.",
                "We encourage you to review the data policies of our current AI service partners:",
                "",
                ["link", "https://openai.com/policies", "OpenAI Data Terms & policies"],
                "",
                ["link", "https://www.anthropic.com/legal/commercial-terms", "Anthropic Commercial Terms"],
                "While these third-party AI services have their own data policies that we must comply with, we are committed to being transparent about our use of external AI capabilities. We ensure any interaction with these services adheres to their terms while safeguarding your rights and interests as much as possible.",
                "Your creative output is respected, and we strive to maintain integrity in our use of AI services, upholding the highest standards of privacy and security within Pod Potion's ecosystem. However, you should be aware that policies around training data usage could change for these or other AI companies we may work with in the future."

            ]
        },




        {
            "title": "Content and Intellectual Property",
            "content": [
                "When you create content using Pod Potion, that content is yours. Here’s how it works:",
                {
                    "Your Content": "Anything you create using Pod Potion remains your intellectual property. Whether it's a podcast script or the final podcast itself, if you created it, you own it."
                },
                {
                    "Our Rights": "By using our service, you give Pod Potion permission to use your content to operate and improve our services. This might include showcasing your podcasts (with your consent) or using them to train our models to get better."
                }
            ]
        },

        {
            "title": "Accuracy of Output",
            "content": [
                "Pod Potion does not guarantee the accuracy, reliability, or suitability of any Output generated through our services. Users should: not rely on Output as the sole source of information or decision-making, independently verify the accuracy and completeness of Output before use, and exercise caution and discretion when using Output, especially in sensitive or critical contexts."
            ]
        },
        {
            "title": "User Responsibilities and Copyright Infringement",
            "content": [
                {
                    "Knowledge of Infringement": "Users of Pod Potion are solely responsible for any copyright infringement claims related to the Output if they were aware, or should have been reasonably aware, that their use of the Output was infringing, or likely to be infringing, on the intellectual property rights of third parties. Users are expected to exercise due diligence in ensuring that their use of the Output does not violate any copyright laws or third-party intellectual property rights."
                },
                {
                    "Disabling or Ignoring Safety Features": "Pod Potion may provide features aimed at reducing the risk of copyright infringement, such as citation tools, content filtering, and safety guidelines. Users who choose not to utilize, disable, or ignore these features and subsequently face copyright infringement claims as a result will be held responsible for such claims."
                },
                {
                    "Modification of Output": "Users who modify, transform, or use the Output in combination with other products or services not provided or authorized by Pod Potion, and then face copyright infringement claims, will be solely responsible for those claims. The responsibility for ensuring that such modifications or combinations do not infringe on the rights of third parties rests entirely with the user."
                },
                {
                    "Unauthorized Input": "Users must ensure that they have the appropriate rights to all content they use as input when generating Output with Pod Potion's services. Users will be responsible for any copyright infringement claims arising from the use of unauthorized input materials."
                },
                {
                    "Trademark Violations": "Users are responsible for ensuring that their use of the Output in trade or commerce does not violate trademark laws or similar rights. Any claims related to trademark violations arising from the user's use of the Output in a commercial context will be the user's responsibility."
                },
                {
                    "Misrepresentation of AI-Generated Content": "Users must not falsely represent AI-generated content as being human-generated or naturally occurring. This includes, but is not limited to, passing off Output from Pod Potion’s services as originating from human creativity, thought processes, or organic data sources when it has, in fact, been generated or substantially influenced by our AI technologies. Users found to be deliberately misrepresenting the nature of the content will be held responsible for any resulting claims or damages."
                },
                {
                    "Misrepresentation of Factual Accuracy": "Given the inherent limitations and variability in AI performance, including the potential for inaccuracies, 'hallucinations,' or content artifacts, users must refrain from representing Output from Pod Potion's services as factual, accurate, or reliable without independent verification. Users are solely responsible for verifying the accuracy and reliability of AI-generated content before its use, especially in contexts where inaccuracies could lead to material consequences or harm. Any claims arising from a failure to verify the factual accuracy or reliability of the Output will be the user's responsibility."
                }
            ]
        },


        // "User Content: As a user of Pod Potion, you retain full ownership of all original content you provide to our platform, including audio files, texts, images, and any other forms of content ('Input'). Additionally, you own the content generated by using our services ('Output'), which may include enhanced audio, transcripts, or any other form of derivative content created through Pod Potion.",

        // "License to Pod Potion: While you retain ownership of your Input and Output, by using our services, you grant Pod Potion a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and display the Input and Output for the sole purpose of providing and improving our services. This includes the right to sublicense such content to our affiliates and service providers as necessary to support the services offered by Pod Potion.",



        // "Pod Potion reserves the right to use all content provided by users (both Input and Output) to maintain, support, and enhance our services. This includes, but is not limited to: Developing new features and functionalities
        // Conducting research to improve existing services.
        // Ensuring the security and integrity of our platform.
        // Pod Potion is committed to respecting user privacy and intellectual property rights and will use user content in compliance with our Privacy Policy and applicable laws.

        // Accuracy of Output

        // Pod Potion does not guarantee the accuracy, reliability, or suitability of any Output generated through our services. Users should:

        // Not rely on Output as the sole source of information or decision-making.
        // Independently verify the accuracy and completeness of Output before use.
        // Exercise caution and discretion when using Output, especially in sensitive or critical contexts.
        // Disclaimer of Warranties

        // Pod Potion provides its services "as is" and without any warranties, express or implied, to the fullest extent permitted by law. This includes disclaiming warranties of merchantability, fitness for a particular purpose, and non-infringement. Pod Potion does not warrant that the services will be uninterrupted, timely, secure, or error-free.

        // Limitation of Liability

        // Pod Potion, its affiliates, and its licensors will not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, use, goodwill, or other intangible losses resulting from:

        // Your access to or use of, or inability to access or use, the services.
        // Any content obtained through the services.
        // Unauthorized access, use, or alteration of your transmissions or content.
        // Our total liability for any claims under these terms, including for any implied warranties, is limited to the amount you paid us to use the services (or, if we choose, to supplying you the services again) in the 12 months preceding the claim.

        // Indemnification

        // You agree to indemnify and hold harmless Pod Potion, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including without limitation, reasonable legal and accounting fees, arising out of or in any way connected with:

        // Your access to, use of, or alleged use of the services.
        // Your breach of these Terms.
        // Your violation of any third-party right, including without limitation any copyright, property, or privacy right.

        {
            "title": "Software in Our Services",
            "content": [
                "Pod Potion may provide you with access to downloadable software or apps as part of our service. This software is part of Pod Potion, and we grant you a personal, non-commercial, revocable, non-transferable license to use it to access our services. Remember, this software is for your use only in connection with Pod Potion and shouldn't be copied, modified, distributed, sold, or leased without our permission."
            ]
        },
        {
            "title": "Feedback and Suggestions",
            "content": [
                "We love hearing from you and welcome your feedback and suggestions about Pod Potion. Please know that by sharing your ideas, you agree that we are free to use them without any restriction or compensation to you. While we might not implement every suggestion, we appreciate your input in helping us improve."
            ]
        },
        {
            "title": "Copyright Infringement Policy",
            "content": [
                "We respect the intellectual property rights of others and expect our users to do the same. If you believe that your copyright has been infringed upon by content generated on Pod Potion, please contact us. We will review all claims of copyright infringement and take appropriate action, including removing infringing content and terminating the accounts of repeat infringers."
            ]
        },
        {
            "title": "Termination",
            "content": [
                "You're free to stop using Pod Potion at any time. We also reserve the right to suspend or terminate your access to our services if we believe you've violated these Terms, engaged in illegal activities, or for any other reason that might harm our service or its users. If your account is terminated, you may lose access to your content, account details, and any other data associated with your account."
            ]
        },
        {
            "title": "Changes to Terms or Services",
            "content": [
                "We might need to change these Terms or update our services occasionally. When we do, we'll let you know by posting the new terms on our website or through other communication methods. By continuing to use Pod Potion after we post these changes, you're saying that you agree to the new terms."

            ]
        },
        {
            "title": "Our Promise and Its Limits",
            "content": [
                "At Pod Potion, we strive to offer you innovative and engaging services just the way they are, which means we can't promise they will meet all your expectations or work perfectly all the time. When you use Pod Potion, you're accepting that we don't offer guarantees that everything will be error-free, always available, secure, or timely.",

                "We also need to let you know that if something doesn't go as planned — like if you lose data, face some interruptions, or run into any other issues while using Pod Potion — we won't be able to compensate you for those indirect troubles or any financial losses that might happen because of them.",

                "That said, we're here to support you within reasonable bounds. If you ever find yourself less than satisfied due to a service issue, we're committed to considering the fees you've paid us for using the service in the last year as a reference for any possible resolution.",

                "Remember, we're on this journey together, and your understanding of these limits helps us continue to innovate and serve you better."
            ]
        }
    ]
    const renderContentItem = (item, index) => {
        if (typeof item === 'string') {
            return <p key={index} className="terms-content">{item}</p>;
        } else if (Array.isArray(item) && item[0] === "link") {
            console.log('render link')
            return <div className="terms-link-container"><a key={index} href={item[1]} target="_blank" rel="noopener noreferrer" className="terms-link">{item[2]}</a></div>;
        } else if (typeof item === 'object') {
            return (
                <ul key={index} className="terms-bullet-list">
                    {Object.entries(item).map(([key, value], idx) => (
                        <li key={idx} className="terms-bullet-item"><strong>{key}:</strong> {value}</li>
                    ))}
                </ul>
            );
        }
    };

    return (
        <div className="terms-container">
            <h1 className="terms-page-title">Terms of Use</h1>
            <h2 className="terms-effective-date">Effective Date: Mar 1, 2024</h2> {/* Adjust the date as needed */}

            {termsData.map((section, index) => (
                <div key={index} className="terms-section">
                    <h2 className="terms-title">{section.title}</h2>
                    {section.content.map((item, pIndex) => renderContentItem(item, pIndex))}
                </div>
            ))}
        </div>
    );

};

export default TermsOfService;
