import React from 'react';
import { useCustomNavigate } from '../general/functions'
import './PricingCard.css'

const PricingCard = ({ plan, plan_description, price, features, buttonLabel, buttonStyle, children }) => {
  const navigateWithParams = useCustomNavigate();
  return (
    <div className="landing-pricing-card">
      <div className="pricing-card-container">
        <span className="pricing-card-heading">{plan.toUpperCase()}</span>
        <span className="pricing-card-plan-description">
          {plan_description}
        </span>
      </div>
      <div className="pricing-card-price-container">
        {price === "0" ? (<span className="pricing-card-price-value">FREE</span>) : (<div>
          <span className="pricing-card-price-dollarsign">
            <span>$</span>
          </span>
          <span className="pricing-card-price-value">{price}</span>
          <span className="pricing-card-price-month">/ episode</span> </div>)}
      </div>
      <div className="pricing-card-features-container">
        {features.map((feature, index) => (
          <div className="pricing-card-single-feature" key={index}>
            <span className="pricing-card-single-feature-checkmark">✔</span>
            <span className="pricing-card-single-feature-description">{feature}</span>
          </div>
        ))}

      </div>
      {/* <button  onClick={() => navigateWithParams('start', {})} className={`pricing-card-button ${buttonStyle}`}>
          {buttonLabel}
        </button> */}
    </div>
  );
};

export default PricingCard;
