// Footer.js
import { Box, Typography, Link, Grid } from '@mui/material';

const PotionsFooter = () => {
    return (
        <Box sx={{ bgcolor: 'background.paper', p: 2 }} component="footer">
            <Typography variant="h2" align="center" color="text.secondary" component="p">
                Pod Potion
            </Typography>

            <Grid container spacing={2} justifyContent="center">
                {/* <Grid item>
                    <Link href="#" color="inherit">
                        About Us
                    </Link>
                </Grid> */}
                <Grid item>
                    <Link href="mailto:podpotion@funcannyai.com" color="inherit">
                        Email
                    </Link>
                </Grid>
                <Grid item>
                    <Link href="/terms" color="inherit">
                        Terms of Use
                    </Link>
                </Grid>
                <Grid item>
                    <Link href="/privacy" color="inherit">
                        Privacy Policy
                    </Link>
                </Grid>
            </Grid>
            <Typography variant="footer1" align="center" color="text.secondary" component="p">
                © 2024 Funcanny Valley AI, All Rights Reserved.
            </Typography>


        </Box>

    );
};

export default PotionsFooter;
