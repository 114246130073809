// SidePanel.js
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import PotionsPlayer from './PotionsPlayer';

const SidePanel = ({ podcast, open, onClose }) => {
    return (
        <Drawer anchor="right" open={open} onClose={onClose}>
            <List>
                <ListItem><ListItemText primary="Title" secondary={podcast?.title} /></ListItem>
                <ListItem><ListItemText primary="Description" secondary={podcast?.description} /></ListItem>
                {/* Additional details */}
            </List>
            
        </Drawer>
    );
};

export default SidePanel;
