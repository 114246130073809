import React, { useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { useCustomNavigate, submitEarlyAccessPrompt } from '../general/functions'
import FeatureCard from '../components/FeatureCard'
import PricingCard from '../components/PricingCard'
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AppstoreOutlined, MailOutlined, SettingOutlined, MenuOutlined, FormOutlined, OrderedListOutlined, InboxOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import ContentCarousel from '../components/Carousel';
import Testimonials from '../components/Testimonials'
import MenuDropdown from '../components/MenuDropdown'
import './Landing.css'
import PotionsFooter from '../potions/PotionsFooter'
import CostCalculator from '../components/CostCalculator'
import Logo from '../components/Logo'

const Landing = (props) => {
    const navigateWithParams = useCustomNavigate();
    const [email, setEmail] = useState('');
    const [prompt, setPrompt] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submitResult, setSubmitResult] = useState('');
    const howItWorks = useRef(null);
    const features = useRef(null);
    const pricing = useRef(null);
    const about = useRef(null);
    const samples = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [episodes, setEpisodes] = useState(5);
    const [minutes, setMinutes] = useState(5);


    const scrollToElement = (elementRef) => {
        const headerHeight = document.querySelector('#navbar-header-id').offsetHeight;
        const elementPosition = elementRef.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };
    const onClick = (e) => {

        switch (e.key) {
            case 'start':
                navigateWithParams('/start', {});
                break;
            case 'login':
                navigateWithParams('/start', {});
                break;
            case 'pricing':
                scrollToElement(pricing);
                break;
            default:
                break;
        }
    };


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const scrollToPricing = (element) => {
        const headerHeight = document.querySelector('#navbar-header-id').offsetHeight;
        const elementPosition = element.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - headerHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };
    // const scrollToEarlyAccess = () => {
    //     const headerHeight = document.querySelector('#navbar-header-id').offsetHeight;
    //     const elementPosition = earlyAccess.current.getBoundingClientRect().top + window.scrollY;
    //     const offsetPosition = elementPosition - headerHeight;

    //     window.scrollTo({
    //         top: offsetPosition,
    //         behavior: 'smooth'
    //     });
    // };

    const handleEarlyAccessSubmit = async (event) => {
        event.preventDefault();
        setSubmitted(true);
        const api_result = await submitEarlyAccessPrompt(email, prompt);
        if (api_result?.early_access_record.PK) {
            setSubmitResult("Thanks for signing up. Your pod potion is brewing. Look for a new PodPotion episode in your email in a few days.")
        } else {
            setSubmitResult("Sorry, there was a problem on our end. Please try again OR email podpotion@funcannyai.com")
        }


        setSubmitted(false);
    };
    const Navbar = () => (
        <div id="navbar-header-id" className="landing-header-v4">
            <header
                data-thq="thq-navbar"
                className="landing-navbar-container-v4"
            >
                <span className="logo" >
                    <img src="./logo512.png" alt="Pod Potion Logo" />
                    <span className="logoText">Pod Potion</span>
                </span>
                {/* <span className="logo">Pod Potion</span> */}
                <div data-thq="thq-navbar-nav" className="landing-desktop-menu-v4">
                    <nav className="landing-nav-links-v4">
                        <span className="landing-nav-1 bodySmall" onClick={() => navigateWithParams('/potions', {})}>Samples</span>
                        <span className="landing-nav bodySmall" onClick={() => scrollToElement(features)}>Features</span>
                        <span className="landing-nav bodySmall" onClick={() => scrollToElement(pricing)}>Pricing</span>
                        <span className="landing-nav bodySmall" onClick={() => navigateWithParams('/register', {})}>Free Preview</span>
                        {/* <span className="landing-nav bodySmall" onClick={() => scrollToElement(earlyAccess)}>Early Access</span> */}
                        {/* <span className="landing-nav bodySmall">Sign in</span> */}
                    </nav>
                    <div className="landing-nav-buttons-v4">
                        <button className="landing-login-v4 buttonFlat" onClick={() => navigateWithParams('/start', {})}>Login</button>
                        <button className="buttonFilled" onClick={() => navigateWithParams('/register', {})}>Register</button>
                    </div>
                </div>
                <MenuDropdown scrollToElement={(element) => scrollToElement(element)} pricing={pricing} samples={samples} />
            </header>
        </div>
    );


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div className="landing-container-v4">

            <Helmet>
                <title>Pod Potion</title>
                <meta property="og:title" content="Pod Potion" />
            </Helmet>
            <Navbar />


            <div ref={about} className="landing-hero-v4">
                <div className="heroContainer landing-hero1-v4">
                    <Box sx={{ flexGrow: 1, padding: theme.spacing(3) }}>
                        <Grid container spacing={isMobile ? 2 : 4} direction={isMobile ? 'column' : 'row'}>


                            {/* Marketing Content */}
                            <Grid item xs={12} md={12}>
                                <div className="landing-container">
                                    <h1 className="landing-hero-heading-v4 heading1">
                                        Custom Podcasts Crafted by AI
                                    </h1>
                                    <ul>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge">Enter a podcast topic.</li>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge">AI generates a complete podcast transcript and recording.</li>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge">Get a new episode in your inbox daily.</li>
                                    </ul>
                                    <div className="landing-btn-group-v4">
                                        <button className="landing-primary-action-button" onClick={() => navigateWithParams('/register', {})}>
                                            <span className="button-main-text">Generate Free Preview</span>
                                            <span className="button-sub-text">→(Sign Up Required)</span>
                                        </button>
                                        <button className=" landing-secondary-action-button" onClick={() => navigateWithParams('/potions', {})}>
                                            <span className="button-main-text">Hear Samples</span>
                                            {/* <span className="button-sub-text">()</span> */}
                                        </button>
                                        {/* <button className="buttonFlat" onClick={() => scrollToElement(features)}>Learn More →</button> */}
                                    </div>


                                </div>
                            </Grid>

                        </Grid>
                    </Box>
                    {/* <Testimonials/> */}
                    {/* 
                    <div className="landing-example-banner-container">
                        <div className="landing-example-banner-subcontainer">
                            <span className="overline">
                                <span>Examples</span>
                                <br></br>
                                <h2>Sample Podcasts Created by Podpotion</h2>
                                <ContentCarousel contents={samples} />
                            </span>
                        </div>
                    </div> */}



                    {/* <div className="landing-container">
                        <h1 className="landing-hero-heading-v4 heading1">
                            Custom Podcasts Crafted by AI
                        </h1>
                        <ul>
                            <li className="landing-hero-sub-heading-v4 bodyLarge">Enter a any podcast topic.</li>
                            <li className="landing-hero-sub-heading-v4 bodyLarge">AI generates a complete podcast transcript and recording.</li>
                            <li className="landing-hero-sub-heading-v4 bodyLarge">Get a new episodes in your inbox daily.</li>
                        </ul>
                        <div className="landing-btn-group-v4">
                            <button className="landing-primary-action-button" onClick={() => navigateWithParams('/register', {})}>
                                <span className="button-main-text">Generate Free Preview</span>
                                <span className="button-sub-text">(Sign Up Required)</span>
                            </button>

                            <button className="buttonFlat" onClick={() => scrollToElement(features)}>Learn More →</button>
                        </div>


                    </div>
                    <h1>test</h1>
                    <ContentCarousel contents={samples} /> */}
                </div>
            </div>
            <div ref={features} className="landing-features-container-v4">
                <div className="landing-container2-v2">
                    <div className="landing-features-container3-v4">
                        <div className="landing-feature-heading-container">
                            <span className="overline">
                                <span>features</span>
                                <br></br>
                            </span>
                            <h2 className="landing-features-heading-v4 heading2">
                                Let AI create the perfect podcast just for you or as a gift
                            </h2>
                            <div className="landing-features-sub-heading-v4 bodyLarge">
                                <span>
                                    Discover the key features that make our platform stand out
                                </span>
                            </div>
                        </div>
                        <div className="landing-feature-card-container">
                            <FeatureCard
                                heading="Simple to get started"
                                subHeading="Enter your topic and our AI will generate a podcast from your prompt."
                            ></FeatureCard>
                            <FeatureCard
                                heading="Infinite Topics"
                                subHeading="Explore boundless possibilities – Your imagination is the only limit."
                            ></FeatureCard>
                            <FeatureCard
                                heading="High Quality Audio and AI voices"
                                subHeading="Dynamic and clear AI voices."
                            ></FeatureCard>
                            <FeatureCard
                                heading="Tailored Experience"
                                subHeading="Update your podcast preferences on the fly."
                            ></FeatureCard>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={pricing} className="home-pricing">
                <div className="pricingContainer">
                    {/* Pricing header */}
                    <div className="home-container04">
                        <span className="overline">
                            <span>Pricing</span>
                            <br></br>
                        </span>
                        <h2 className="heading2">Simple Pricing</h2>
                        <span className="home-pricing-sub-heading bodyLarge">
                            <span>
                                <span>More Plans Coming Soon</span>
                            </span>
                        </span>
                    </div>
                    {/* Pricing cards */}
                    <div className="home-container05"  >
                        <PricingCard
                            plan="Podcast Ideation + Preview"
                            plan_description="Try before you buy."
                            price="0"
                            features={[
                                "Unlimited Podcast Ideation",
                                "~1 minute of audio for preview/trailer",
                                "Generate Full Podcast Episodes Outline",
                            ]}
                            buttonLabel="Continue"
                            buttonStyle="buttonOutline"
                        // scrollToElement={scrollToEarlyAccess}
                        />
                        <PricingCard
                            plan="Introductory Plan"
                            plan_description="Pay per episode"
                            price="2"
                            features={[
                                "~5 minutes of audio content/episode",
                                "Highest quality LLM and audio models",
                                "Format: 2 AI co-hosts interacting",
                                "Downloadable mp3 file",
                                // "Transcript" TBD
                            ]}
                            buttonLabel="Continue"
                            buttonStyle="buttonFilledSecondary"
                        // children={<CostCalculator setMinutesPerEpisode={setMinutes} setNumEpisodes={setEpisodes} numEpisodes={episodes} minutesPerEpisode={minutes}/>}
                        // scrollToElement={scrollToEarlyAccess}
                        />
                        {/* <PricingCard
                            plan="Pro"
                            plan_description="A premium plan with advanced features"
                            price="29.99"
                            features={[
                                "All  features of BASIC plan",
                                "20 Episodes / month",
                                "100 Minutes of Total Audio / month",
                                "Evolve your podcast: give feedback to shape future episodes"
                            ]}
                            buttonLabel="Try the PRO plan"
                            buttonStyle="buttonFilledSecondary"
                            scrollToElement={scrollToEarlyAccess}
                        /> */}
                    </div>
                </div>

            </div>
            <div ref={howItWorks} className="landing-features-container-v4">
                <div className="landing-waitlist-container">
                    <div className="landing-container2-v2">
                        <div className="landing-features-container3-v4">
                            <div className="landing-feature-heading-container">
                                <div className="home-container04">
                                    <span className="overline">
                                        <span>How it works</span>
                                        <br></br>
                                    </span>
                                    <h2 className="heading2">Enter a podcast prompt, Pod Potion does the rest...</h2>
                                    <div className="home-pricing-sub-heading bodyLarge">
                                        <div className="landing-howitworks-list">
                                            <FeatureCard
                                                icon={<FormOutlined />}
                                                heading="Enter a podcast prompt"
                                                subHeading="Just enter a topic or description of the podcast you wish to generate"
                                            ></FeatureCard>
                                            <FeatureCard
                                                icon={<OrderedListOutlined />}
                                                heading="Choose # of episodes"
                                                subHeading="Pick how many 5 minute episodes you want to generate."
                                            ></FeatureCard>
                                            <FeatureCard
                                                icon={<img className="logo-feature-card" src="./logo512.png" alt="Pod Potion Logo" />}
                                                heading="Pod Potion AI generates complete podcast"
                                                subHeading="Includes an episode outline + preview for free; transcript and audio recording can be purchased"
                                            ></FeatureCard>
                                            <FeatureCard
                                                icon={<CustomerServiceOutlined />}
                                                heading="New episodes daily"
                                                subHeading="Get a new episode in your email inbox daily"
                                            ></FeatureCard>
                                            {/* <ul>
                                                <li className="landing-hero-sub-heading-v4 bodyLarge landing-howitworks-li">1. Just enter a podcast prompt</li>
                                                <li className="landing-hero-sub-heading-v4 bodyLarge landing-howitworks-li">2. Choose your # of episodes</li>
                                                <li className="landing-hero-sub-heading-v4 bodyLarge landing-howitworks-li">3. AI generates entire podcast (outline, transcript, and audio recording) </li>
                                                <li className="landing-hero-sub-heading-v4 bodyLarge landing-howitworks-li">4. Get a new episodes in your inbox daily.</li>
                                            </ul> */}
                                            {/* <span className="landing-hero-sub-heading-v4 bodyLarge">Simply share the theme of your podcast and choose your episode's length for a no-obligation price quote.</span>
                                            <span className="landing-hero-sub-heading-v4 bodyLarge">AI generates a complete podcast including an outline, transcript and audio recording.</span>
                                            <span className="landing-hero-sub-heading-v4 bodyLarge">Get a new episodes in your inbox daily.</span> */}
                                        </div>
                                    </div>
                                </div>
                                <span className="overline">
                                    <span></span>
                                    <br></br>
                                </span>

                                {/* 
                                <div className="landing-features-sub-heading-v4 bodyLarge">
                                    <ul>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge"> <strong>Select Your Topic and Episode Length:</strong></li>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge"> Simply share the theme of your podcast and choose your episode's length for a no-obligation price quote.</li>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge">AI generates a complete podcast including an outline, transcript and audio recording.</li>
                                        <li className="landing-hero-sub-heading-v4 bodyLarge">Get a new episodes in your inbox daily.</li>
                                    </ul>


                                </div> */}
                                <div className="button-call-to-action-container">
                                    <button className="landing-primary-action-button" onClick={() => navigateWithParams('/register', {})}>
                                        <span className="button-main-text">Generate Free Preview</span>
                                        <span className="button-sub-text">(Sign Up Required)</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div ref={earlyAccess} className="landing-features-container-v4">
                <div className="landing-waitlist-container">
                    <div className="landing-container2-v2">
                        <div className="landing-features-container3-v4">
                            <div className="landing-feature-heading-container">
                                <span className="overline">
                                    <span>early access</span>
                                    <br></br>
                                </span>

                                <h2 className="landing-features-heading-v4 heading2" >
                                    Launching Soon! Until Then...
                                </h2>

                                {!submitResult ? (
                                    <div className="landing-features-sub-heading-v4 bodyLarge">
                                        <div className="landing-features-sub-heading-v4 bodyLarge">
                                            <span>
                                                Enjoy a free 3 episode sample when you sign up
                                            </span>
                                        </div>
                                        <div className="landing-signup-form-container">
                                            <form className="landing-signup-form" onSubmit={handleEarlyAccessSubmit} disabled={submitted}>
                                                <label className="landing-signup-form-span" >Enter your email</label>
                                                <input
                                                    className="landing-signup-form-input-email"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="example@domain.com"
                                                    required
                                                    disabled={submitted}
                                                />

                                                <label className="landing-signup-form-span prompt-span-padding">Enter a podcast prompt</label>
                                                <textarea
                                                    className="landing-signup-form-input-prompt"
                                                    type="textarea"
                                                    value={prompt}
                                                    onChange={(e) => setPrompt(e.target.value)}
                                                    placeholder="Example: 'Create a series on the history of AIs' OR 'Teach me about blue whales'"
                                                    required
                                                    disabled={submitted}
                                                />
                                                <button className="buttonFilled" type="submit" disabled={submitted}>Sign Up</button>
                                            </form>
                                        </div>
                                    </div>
                                ) : (
                                    <p>{submitResult}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="landing-footer-v4">
                <footer className="footerContainer">
                    <div className="landing-footer-subcontainer-v4">
                        <nav className="landing-nav1-v4 bodySmall">
                            <a className="landing-footer-nav1-v4 bodySmall" href="mailto:podpotion@funcannyai.com" >Contact</a>
                            <a className="landing-footer-nav1-v4 bodySmall" href="/privacy" >Privacy Policy</a>
                            <a className="landing-footer-nav1-v4 bodySmall" href="/terms" >Terms of Service</a>
                            {/* <span className="landing-footer-nav1-v4 bodySmall" onClick={() => navigateWithParams('/terms', {})}>Terms of Service</span>
                            <span className="landing-footer-nav1-v4 bodySmall" onClick={() => navigateWithParams('/privacy', {})}>Privacy</span> */}
                            {/* <span className="landing-footer-nav1-v4 bodySmall" onClick={() => scrollToElement(about)}>About</span>
                            {/* <span className="landing-footer-nav1-v4 bodySmall" onClick={() => scrollToElement(about)}>About</span>
                            <span className="landing-footer-nav-v4 bodySmall" onClick={() => scrollToElement(features)}>Features</span>
                            <span className="landing-footer-nav-v4 bodySmall" onClick={() => scrollToElement(pricing)} >Pricing</span>
                            <span className="landing-footer-nav-v4 bodySmall" onClick={() => navigateWithParams('/register', {})} >Free Preview</span> */}
                            {/* <span className="landing-footer-nav-v4 bodySmall">Sign in</span> */}
                        </nav>
                    </div>
                    <div className="landing-footer-separator-v4"></div>
                    <span className="bodySmall landing-allrightstext-v4">
                        © 2024 Funcanny Valley AI, All Rights Reserved.
                    </span>
                    {/* <div className="home-icon-group1">
                        <svg
                            viewBox="0 0 950.8571428571428 1024"
                            className="home-icon10 socialIcons"
                        >
                            <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                        </svg>
                        <svg
                            viewBox="0 0 877.7142857142857 1024"
                            className="home-icon12 socialIcons"
                        >
                            <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                        </svg>
                        <svg viewBox="0 0 1024 1024" className="home-icon12 socialIcons" xmlns="http://www.w3.org/2000/svg">
                            <path d="M911.5 0H112.5C50.5 0 0 50.5 0 112.5v799c0 62 50.5 112.5 112.5 112.5h799c62 0 112.5-50.5 112.5-112.5v-799C1024 50.5 973.5 0 911.5 0zm-639 853.5H162V369.5h110.5v484zm-54.75-553.25c-35.75 0-64.75-29-64.75-64.75s29-64.75 64.75-64.75 64.75 29 64.75 64.75-29 64.75-64.75 64.75zm553.25 553.25H722V583c0-64.5-1.25-147.25-89.75-147.25-89.75 0-103.5 70.25-103.5 142.5v274.25H418.5V369.5h106.25v66h1.5c14.75-28 51-89.75 104.75-89.75 112.25 0 133 74 133 170.25v337.5z" />
                        </svg>
                        <img src={`${process.env.PUBLIC_URL}/path/to/your/linkedin.svg`} alt="LinkedIn" className="social-icon" />
        
                        <svg
                            viewBox="0 0 602.2582857142856 1024"
                            className="home-icon14 socialIcons"
                        >
                            <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                        </svg>
                    </div> */}

                </footer>
            </div>
        </div>

    )
}

export default Landing
