import React, { useState } from 'react';
import { Switch, Typography, Box, ThemeProvider, createTheme } from '@mui/material';
import SimpleStart from '../views/SimpleStart';
import MultiStepForm from './CreateFormStepper'
// import AdvancedFlow from './AdvancedFlow'; // Adjust the import path as needed

const theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'h6' },
          style: {
            fontSize: '1.2rem',
            fontWeight: '500',
            margin: '0 10px',
          },
        },
      ],
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#888',
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: '#0b76ef',
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: '#888',
          '&.Mui-checked': {
            opacity: 0.7,
            backgroundColor: '#0b76ef',
          },
        },
      },
    },
  },
});

const Create = () => {
  const [isSimple, setIsSimple] = useState(true);

  const handleSwitchChange = () => {
    setIsSimple(!isSimple);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h6">Simple</Typography>
          <Switch
            checked={!isSimple}
            onChange={handleSwitchChange}
          />
          <Typography variant="h6">Advanced</Typography>
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          {isSimple ? <SimpleStart /> :<MultiStepForm/>}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Create;
