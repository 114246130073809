import { useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_API_URL;

// Abstract common logic for making the API call
const makeApiRequest = async (params, route, supressErrors = true) => {
    const endpoint = apiUrl + route;
    try {
        const response = await axios.get(endpoint, { params });
        return response;
    } catch (error) {
        console.error('API call failed:', error?.response?.status);
        if (!supressErrors) {
            throw error;
        }
        return null;
    }
};


export const submitEarlyAccessPrompt = async (email, prompt) => {
    const params = {
        'action': 'early_access_signup',
        'user_info': email,
        'user_info_type': 'email',
        'prompt': prompt,
        'context': 'early_access'
    };

    const response = await makeApiRequest(params, '/podpotionsystem');
    return response?.data;
}

export const getPodpotionConfig = async (pk, token) => {
    const params = {
        'action': 'get_podcast_config',
        'PK': pk,
    };
    if (token !== '') {
        params['token'] = token;

    }

    const response = await makeApiRequest(params, '/podpotionget', false);
    return response?.data?.podpotion_config;

};

export const getPodpotionsByUser = async (token, temp) => {
    const params = {
        'action': 'get_user_podcasts',
        'token': token,
        'params': temp
    };
    if (token !== '') {
        params['token'] = token;

    }

    const response = await makeApiRequest(params, '/podpotionget', false);
    return response?.data?.podcasts;

};

export const getEpisodes = async (pk, token) => {
    const params = {
        'action': 'get_episode',
        'PK': pk,
    };
    if (token !== '') {
        params['token'] = token;

    }
    const response = await makeApiRequest(params, '/podpotionget', false);
    return response?.data?.episode ;

};

export const getEpisode = async (pk, sk, token) => {
    const params = {
        'action': 'get_episode',
        'PK': pk,
        'SK': sk,
    };
    if (token !== '') {
        params['token'] = token;

    }
    const response = await makeApiRequest(params, '/podpotionget', false);
    return response?.data?.episode;

};

export const generateEpisode = async (pk, sk, token) => {
    const params = {
        'action': 'generate_purchased_episode',
        'PK': pk,
        'SK': sk,
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotion', false);
    return response?.data?.episode;

};


export const getTranscript = async (pk, sk, token) => {
    const params = {
        'action': 'get_transcript',
        'PK': pk,
        'SK': sk,
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotion');
    return response?.data?.transcript;

};


export const toggleIsPublic = async (pk, isPublic, token) => {
    const params = {
        'action': 'toggle_public',
        'PK': pk,
        'public': isPublic,
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotion');
    return response
};


export const loadProfile = async (token, setProfile) => {
    const params = {
        'action': 'get_profile',
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotionauth');
    setProfile(response?.data);

};

export const updateProfile = async (token, setProfile) => {
    const params = {
        'action': 'get_profile',
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotionauth');
    setProfile(response?.data);

};



export const NavigateToApp = (path) => {
    const navigate = useNavigate();
    navigate(path)
};


export const useCustomNavigate = () => {
    const navigate = useNavigate();

    const navigateWithParams = (path, params = {}) => {
        const queryString = Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        navigate(`${path}?${queryString}`);
    };

    return navigateWithParams;
};


export const createPodPotionConfig = async (prompt, episode_length, minutes, token) => {
    const params = {
        'action': 'create_config',
        'prompt': prompt,
        'episode_length': episode_length,
        'minutes': minutes,
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotion');
    return response?.data?.config;

};

export const getPodPotionOutline = async (pk, token) => {
    const params = {
        'action': 'get_config',
        'PK': pk,
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotion');
    return response?.data?.config;

};

export const getPodPotionEpisodes = async (pk, token) => {
    const params = {
        'action': 'get_episode',
        'PK': pk,
        'token': ''
    };
    const response = await makeApiRequest(params, '/podpotion');
    return response?.data;

};


// export const callSendAudio = async (pk, sk) => {
//     const params = {
//         'action': 'create_podpotion_audio',
//         'PK': pk,
//         'SK': sk
//     };
//     const response = await makeApiRequest(params, '/podpotion');
//     return response?.data?.config;
// };


export const submitFeedback = async (feedback, userInfoType, userInfo, context, token) => {
    const params = {
        'action': 'submit_feedback',
        'feedback': feedback,
        'user_info_type': userInfoType,
        'user_info': userInfo,
        'context': context,
        'token': token
    };
    const response = await makeApiRequest(params, '/podpotionsystem');
    return response?.data;
}


export const checkUserAuthentication = async (setUser) => {
    try {
        const userInfo = await Auth.currentAuthenticatedUser();
        setUser(userInfo);
    } catch (error) {
        console.error("Error fetching user: ", error);
    }
}

export const checkUserAuthentication2 = async () => {
    try {
        const userInfo = await Auth.currentAuthenticatedUser();
        return userInfo;
    } catch (error) {
        console.error("Error fetching user: ", error);
    }
}


export const createPaymentIntent = async (token, credits, podPotionId) => {
    const params = {
        'action': 'create_payment_intent',
        'token': token,
        'price_id': 'price_1OqgbOGI13dLYQwWeNqcQ7sq',
        'credits': credits,
        'podpotion_id': podPotionId
    };
    const response = await makeApiRequest(params, '/podpotionsystem');
    return response;

};