import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useCustomNavigate } from '../general/functions';
import './SignInForms.css';

const SignInForm = () => {
    const [searchParams] = useSearchParams();
    const navigateWithParams = useCustomNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forgotPassword, setForgotPassword] = useState(false);
    const [error, setError] = useState(null);
    const protected_path = decodeURIComponent(searchParams.get('protected_path')|| '/start')
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handlePasswordReset = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            setError(null);
            await Auth.forgotPassword(email);

            navigateWithParams('/verification', { 'email': email, 'promotions': true, 'protected_path': protected_path });
        } catch (err) {
            setError(err.message);
        } finally {
            setIsSubmitting(false); 
        };
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            setError(null);
            const user = await Auth.signIn(email, password);
            navigateWithParams(protected_path, {})
            // navigate(location.state.pathname || '/', { state: { ...location.state.state, prevState: location } });
        } catch (err) {
            console.log('err', err);
            setError(err.message);
        } finally {
            setIsSubmitting(false); 
        };
    };

    return (
        <div className="sign-in-container">
            <div className="logo-container">
                {/* Replace 'logo.png' with your actual logo path */}
                {/* <img src="logo.png" alt="Logo" className="logo"/> */}
            </div>
            <div className="welcome-container">
                <h2>Pod Potion</h2>
                <p>Welcome Back to Pod Potion</p>
            </div>
            {forgotPassword ? <div><form onSubmit={handlePasswordReset} className="sign-in-form">

                <div className="input-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={isSubmitting}
                    />
                </div>
                <button type="submit" className="sign-in-button" disabled={isSubmitting}>{isSubmitting ? 'Sending Link...' : 'Send Reset Link'}</button>

            </form><div className="account-exists"> <span
                className="back-to-login"
                onClick={() => setForgotPassword(false)}
            >
                Back to Login
            </span> </div> </div> : <div> <form onSubmit={handleLogin} className="sign-in-form">

                <div className="input-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={isSubmitting}
                    />
                </div>
                <div className="input-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={isSubmitting}
                    />
                </div>
                <div
                    className="forgot-password"
                    onClick={() => setForgotPassword(true)}
                >
                    Forgot Password?
                </div>
                <button type="submit" className="sign-in-button" disabled={isSubmitting}>{isSubmitting ? 'Signing In...' : 'Sign In'}</button>
                {error && (<div className="login-error">
                <p className="login-error-message">{error}</p>
            </div>)}
            </form><div className="account-exists">
                    New to Pod Potion? <a className='forgot-password' onClick={() => navigateWithParams('/register', {})}>Create an account</a>
                </div>
            </div>}

            
        </div>
    );
};

export default SignInForm;
