import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useCustomNavigate } from '../general/functions';
import { MenuOutlined, CloseOutlined, LoginOutlined, PlayCircleOutlined, DollarOutlined } from '@ant-design/icons';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined';
import './MenuDropdown.css';


const MenuDropdown = ({ scrollToElement, pricing, samples}  ) => {
    const navigateWithParams = useCustomNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [isMember, setIsMember] = useState(false);
    const location = useLocation();


    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userInfo = await Auth.currentAuthenticatedUser({ bypassCache: true })
                setUser(userInfo);
            } catch (error) {
                console.log(error);
            }
        };

        fetchUser();
    }, [location, isOpen]);

    useEffect(() => {
        setIsMember(user?.attributes?.['custom:member'] === '1');
    }, [user, isOpen]);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleMenuClick = (event) => {
        console.log('event', event,pricing, samples)
        toggleDropdown();
        switch (event) {
            case 'register':
                navigateWithParams('/register', {});
                break;
            case 'login':
                navigateWithParams('/login', {});
                break;
            case 'pricing':
                scrollToElement(pricing);
                break;
            case 'samples':
                navigateWithParams('/potions', {});
                break;
            default:
                break;
        }

    };


    const goTo = (url) => {
        if (url.startsWith('https://')) {
            window.open(url, '_blank');
        } else {
            window.location.href = `${window.location.origin}/${url}`
            // navigate(`/${url}`, { state: location });

        }

        toggleDropdown();
    };

    // const handleLogin = (event) => {
    //     event.preventDefault();
    //     toggleDropdown();
    //     navigate('/signin', { state: location });
    // };

    // const handleLogout = async (event) => {
    //     event.preventDefault();
    //     toggleDropdown();
    //     try {
    //         await Auth.signOut();
    //         setUser(null);
    //         navigate('/');
    //     } catch (error) {
    //         setUser(null);
    //         navigate('/');
    //     }
    // };
    // background-image: url('/public/logo.png');
    return (
        <div className="menu-dropdown">
            <div className="menu-dropdown-toggle" onClick={toggleDropdown}>
                {isOpen ? <CloseOutlined /> : <MenuOutlined />}

            </div>
            <div className={`menu-dropdown ${isOpen ? 'open' : ''}`}>
                {isOpen && (
                    <div className="menu-dropdown-menu">
                        <ul className="menu-dropdown-menu-list">
                            <li className="menu-dropdown-item" onClick={() => handleMenuClick('login')}><LoginOutlinedIcon /><label className="menu-dropdown-label">Login</label></li>
                            <li className="menu-dropdown-item" onClick={() => handleMenuClick('register')}><PlayCircleOutlineOutlinedIcon /><label className="menu-dropdown-label">Preview</label></li>
                            <li className="menu-dropdown-item" onClick={() => handleMenuClick('pricing')}><PaidOutlinedIcon /><label className="menu-dropdown-label">Pricing</label></li>
                            <li className="menu-dropdown-item" onClick={() => handleMenuClick('samples')}><HeadphonesOutlinedIcon /><label className="menu-dropdown-label">Samples</label></li>
                            {/* <li className="menu-dropdown-item" onClick={() => goTo('')}><label className="menu-dropdown-label">home</label></li>
                        {user ? (
                            <div>
                            <li className="menu-dropdown-item" onClick={() => goTo('account')}><label className="menu-dropdown-label" >account</label></li>
                            <li className="menu-dropdown-item" onClick={handleLogout}><label className="menu-dropdown-label" >log out</label></li>
                            </div>
                        ) : (
                            <li className="menu-dropdown-item" onClick={handleLogin}><label className="menu-dropdown-label" >register/log in</label></li>
                        )}

                        <li className="menu-dropdown-item" onClick={() => goTo('#feedback')}><label className="menu-dropdown-label">feedback</label></li> */}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MenuDropdown