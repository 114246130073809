import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { Drawer, Collapse, } from 'antd';
import { SyncOutlined, CommentOutlined, ScheduleOutlined } from '@ant-design/icons';
import {
  getEpisodes, getPodpotionConfig, useCustomNavigate, checkUserAuthentication,
  checkUserAuthentication2, createPaymentIntent, generateEpisode,
  toggleIsPublic
} from '../general/functions';
import Feedback from '../components/Feedback';
import { useUser } from '../components/UserContext';
import ReactGA from 'react-ga4';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from './Checkout';
import Logo from '../components/Logo';
import Switch from '@mui/material/Switch';
import AudioPlayer from './AudioPlayer';
import ShareButton from '../components/ShareButton';
import './PodOutline.css'


const stripePromise = loadStripe(`${process.env.REACT_APP_CLIENT_PK}`);


const LOADING_STATES = {
  Empty: 'empty',
  CreatingOutline: 'creating_outline',
  InProgress: 'in_progress',
  Ready: 'ready',
  NoAccess: 'forbidden',
  Error: 'Error'
};


const PrashantPage = () => {
  const navigateWithParams = useCustomNavigate();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  // const [loading, setLoading] = useState(true);
  const [episodes, setEpisodes] = useState('');
  const [purchased, setPurchased] = useState(false);
  const [user, setUser] = useState('');
  const [podcastConfig, setPodcastConfig] = useState('');
  const [loadingState, setLoadingState] = useState(LOADING_STATES.CreatingOutline);
  const { userContext } = useUser();
  const audioRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState('left');


  const domain = process.env.REACT_APP_DOMAIN;
  const [options, setOptions] = useState({ clientSecret: '' });
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const [publicChecked, setPublicChecked] = useState('');

  const handlePublicChange = (pk) => {
    toggleIsPublic(pk, !publicChecked, user?.signInUserSession?.accessToken?.jwtToken);
    setPublicChecked(!publicChecked);
  };

  const toggleFeedbackDrawer = () => {
    setIsFeedbackOpen(!isFeedbackOpen);
  };

  const loadTranscript = (pk, sk) => {
    window.open(`https://${domain}/transcript?id=${pk}&episode=${sk}`, '_blank', 'noopener,noreferrer');
  };

  const showDrawer = () => {
    setOpen(true);


  };


  const generateContent = (pk, sk) => {
    generateEpisode(pk, sk, user?.signInUserSession?.accessToken?.jwtToken);
    window.location.reload();

  };
  useEffect(() => {
    const { search } = location;
    const queryParams = new URLSearchParams(search);

    if (queryParams.has('setup_intent') || queryParams.has('setup_intent_client_secret') || queryParams.has('redirect_status')) {
      queryParams.delete('setup_intent');
      queryParams.delete('setup_intent_client_secret');
      queryParams.delete('redirect_status');

      navigate(`${location.pathname}?${queryParams}`, { replace: true });
    }
  }, [navigate, location]);

  const onClose = () => {
    setOpen(false);
  };
  const handleAudioClick = () => {

    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'PodPlayer Button Click'
    });
  }
  const systemDynamicInterval = (errorCount, stateCount, totalCount) => {
    let interval = 5000;
    if (errorCount > 6000 || stateCount > 40000 || totalCount > 300000) {
      interval = 10000;
    }
    if (errorCount > 21000 || stateCount > 180000 || totalCount > 600000) {
      interval = 30000;
    }
    if (errorCount > 21000 || stateCount > 1200000 || totalCount > 1800000) {
      if (errorCount > 21000) {
        setLoadingState(LOADING_STATES.Error)
      }
      interval = 0;
    }
    return interval

  }
  function arraysMatch(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }

  const simplifyArray = (response_input, attributesToCompare) => {
    let response_array = Array.isArray(response_input) ? response_input : [response_input];

    return response_array.map(item => {
      const simplifiedItem = {};
      attributesToCompare.forEach(attribute => {
        simplifiedItem[attribute] = item?.[attribute];
      });
      return JSON.stringify(simplifiedItem);
    });
  };

  const usePolling = (fetchFunction, setterFunction, shouldStopPolling, visibleAttributes, dynamicInterval = 8000) => {
    // const [data, setData] = useState(null);
    // const [isLoading, setIsLoading] = useState(true);
    let state_response = []
    let errorCounter = 0;
    let totalCounter = 0;
    let stateCounter = 1;

    useEffect(() => {
      const intervalId = setInterval(async () => {
        try {
          if (user?.signInUserSession?.accessToken?.jwtToken || true) {
            const response = await fetchFunction();
            if (JSON.stringify(state_response) !== JSON.stringify(response)) {
              stateCounter = 1;

              if (!arraysMatch(simplifyArray(state_response, visibleAttributes), simplifyArray(response, visibleAttributes))) {
                state_response = response;
                setterFunction(response);
              }

            }


            dynamicInterval = systemDynamicInterval(errorCounter, stateCounter, totalCounter);
            if (shouldStopPolling(response) || dynamicInterval === 0) {
              // setIsLoading(false);
              clearInterval(intervalId);
            }
            errorCounter = 0;
          }

        } catch (error) {
          console.error('Polling error:', error);
          errorCounter += dynamicInterval;
          dynamicInterval = systemDynamicInterval(errorCounter, stateCounter, totalCounter);
          if (dynamicInterval === 0) {
            clearInterval(intervalId);
          }
        }
        stateCounter += dynamicInterval;
        totalCounter += dynamicInterval;
      }, dynamicInterval);

      return () => clearInterval(intervalId);
    }, [user]);
    return {}
    // return { data, isLoading };
  };


  // const { data: dataA, isLoading: isLoadingA } = usePolling(
  usePolling(
    () => getPodpotionConfig(searchParams.get('id'), user?.signInUserSession?.accessToken?.jwtToken),
    setPodcastConfig,
    (response) => response?.title,
    ['title', 'purchase_status']
  );

  // function callPolling() {
  // // const { data: dataB, isLoading: isLoadingB } = usePolling(
  // const  startEpisodePolling = ()=>  {
  //   usePolling(
  //     () => getEpisodes(searchParams.get('id'), user?.signInUserSession?.accessToken?.jwtToken),
  //     setEpisodes,
  //     determineEpisodeStatus,
  //     ['episode_number', 'audio_url']

  //   );
  // }
  // startEpisodePolling();
  usePolling(

    () => getEpisodes(searchParams.get('id'), user?.signInUserSession?.accessToken?.jwtToken),
    setEpisodes,
    determineEpisodeStatus,
    ['episode_number', 'audio_url']

  );

  useEffect(() => {
    setPurchased(podcastConfig?.purchase_status);
    setPublicChecked(podcastConfig?.is_public === 'true')
    if (podcastConfig?.title && episodes?.[0]?.audio_url && +podcastConfig?.episode_length === episodes?.length - 1) {
      setLoadingState(LOADING_STATES.Ready);
    }
  }, [podcastConfig, episodes]);

  // useEffect(() => {
  //   setLoading(isLoadingB)

  // }, [isLoadingB]);


  function determineEpisodeStatus(episodes) {
    if (!episodes || episodes === '') {
      return false;
    }

    if (episodes?.[0]?.audio_url === '') {
      return false;
    }
    else if (episodes?.length <= 1) {
      return false;
    }
    else {
      const statusList = ['intialized', 'queued', 'completed'];
      let episode_status = true;
      episodes.forEach(episode => {
        if (!statusList.includes(episode?.internal_status)) {
          episode_status = false;
        }
      });
      return episode_status;
    }
  }


  function EpisodeContent({ episode }) {
    const in_progress = ['creating_conversation', 'setup_conversation']
    if (episode?.audio_url) {
      return (
        <div>
          {/* <audio ref={audioRef} className='audio-player' controls src={episode?.audio_url} onClick={handleAudioClick}>
            Your browser does not support the audio element.
          </audio> */}
          <AudioPlayer url={episode?.audio_url} />
          <button onClick={toggleFeedbackDrawer} className='podplayer-feedback-button'>
            <div className='podplayer-feedback-drawer'> <CommentOutlined /></div>
          </button>
          {podcastConfig?.user_owned &&
            <button onClick={() => loadTranscript(episode.PK, episode.SK)} className='buttonFlat'>
              <div>  Get Transcript</div>
            </button>}
        </div>
      );
    } else if (in_progress.includes(episode.internal_status)) {
      return <div className='podplayer-preparing-content'><SyncOutlined spin /> Preparing Your Content</div>;
    } else if (podcastConfig?.purchase_status) {
      return <div className='podplayer-coming-soon'>
        <span><ScheduleOutlined /> Scheduled: {episode.scheduled_date}</span>
        {podcastConfig?.user_owned && <><div>OR</div>
          <button className="buttonFlat podplayer-create-episode" onClick={() => generateContent(episode.PK, episode.SK)}> Get ASAP </button></>}
      </div>;
    } else if (!podcastConfig?.purchase_status) {
      return <>{podcastConfig?.user_owned && <div className='podplayer-purchase-message'>Purchase to Unlock</div>}</>;
    }
  }

  function StateMessages({ state }) {
    if (state === LOADING_STATES.Forbidden) {
      return (
        <div>
          You do not have access
        </div>
      );
    } else if (state === LOADING_STATES.Error) {
      return <div className='podplayer-prompt-container'>
        <div className='wait-message'>Sorry, there seems to be an Error. Please check your link and try reloading the page. If the issue persists, please send us a message.</div>
        <button onClick={toggleFeedbackDrawer} className='buttonFlat'>
          <CommentOutlined /> Report Issue
        </button>
      </div>;
    } else {
      return <div>
        <div className="wait-message">
          <div>(this may take a few minutes)</div>
        </div>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
        <div className="wait-message">
          <div>Please wait while your podcast outline is being generated or retrieved.</div>
        </div>
        <div className="wait-link-to-podcast">
          While you wait, feel free to check out Pod Potion's
          <a className="wait-link-to-podcast-link" href="https://open.spotify.com/show/5jtN0tBMvuNhteNuPXSwPK?si=dd8c0debc72b4097" target="_blank" rel="noopener noreferrer">AI on AI</a>
          podcast on Spotify
        </div>
      </div>;
    }
  }

  useEffect(() => {
    const presetup = async () => {
      const user = await checkUserAuthentication2();
      if (user && user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.jwtToken && searchParams.get('id')) {
        try {
          const { data } = await createPaymentIntent(user.signInUserSession.accessToken.jwtToken, 2, searchParams.get('id'));
          setOptions({ clientSecret: data?.pis });
        } catch (error) {
          console.error("Error creating payment intent:", error);
        }
      } else {
        console.warn("Required values for creating payment intent are missing.");
      }
    };

    presetup();
  }, [episodes]);

  // useEffect(() => {
  //   const handleAudioPlay = () => {
  //     ReactGA.event({
  //       category: 'Audio',
  //       action: 'Play',
  //       label: 'PodPlayer Button Click'
  //     });
  //   };

  //   const audioElement = audioRef.current;
  //   if (audioElement) {
  //     audioElement.addEventListener('playing', handleAudioPlay);
  //   }

  //   return () => {
  //     if (audioElement) {
  //       audioElement.removeEventListener('playing', handleAudioPlay);
  //     }
  //   };
  // }, [loading]);

  useEffect(() => {
    if (userContext) {
      setUser(userContext);
    } else {
      checkUserAuthentication(setUser);
    }
  }, []);

  const GuestInfo = ({ name, blurb, linkedinUrl, imageUrl }) => {
    return (
      <div className="guest-info">
        <div className="guest-image">
          <img src={imageUrl} alt={name} />
        </div>
        <div className="guest-details">
          <h3>{name}</h3>
          <p>{blurb}</p>
          <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className="linkedin-link">
            <img
              src={process.env.PUBLIC_URL + '/linkedin.png'}
              alt="LinkedIn"
              className="linkedin-icon"
            />
            Connect on LinkedIn
          </a>
        </div>
      </div>
    );
  };

  const AuthorSection = ({ author, author_imageUrl, linkedinUrl, otherBlogTitle }) => {
    return (
      <div className="author-section">
        <div className="author-info">
          <img
            src={author_imageUrl}
            alt={`${author}'s profile picture`}
            className="author-image"
          />
          <div className="author-details">
            <h3 className="author-name">{author}</h3>
            <a
              href={linkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin-link"
            >
              LinkedIn Profile
            </a>
          </div>
        </div>
        <div className="other-blog-post">
          <h4>Read another post by {author}:</h4>
          <h2>{otherBlogTitle}</h2>
          {/* <a 
            href={otherBlogPost.url} 
            className="blog-post-link"
          >
            {otherBlogPost.title}
          </a> */}
        </div>
      </div>
    );
  };
  const PodcastGuest = ({ name, description, linkedinUrl, imageUrl }) => {
    return (
      <div className="podplayer-episode-container">
        <h2 className="podplayer-episode-title">About the Guest</h2>
        <div style={styles.header}>
          <img src={imageUrl} alt={name} style={styles.image} />
          <h3 className="podplayer-episode-title">{name}</h3>
        </div>
        <p lassName="podplayer-episode-description">{description}</p>
        <div className='audio-player-container'>
          <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" style={styles.linkedinButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" style={styles.icon}>
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
            Connect
          </a>
        </div>
      </div>
    );
  };

  const styles = {
    container: {
      backgroundColor: '#f0f4f8',
      borderRadius: '12px',
      padding: '2rem',
      maxWidth: '400px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Arial, sans-serif',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1rem',
    },
    image: {
      width: '50px',
      height: '50px',
      borderRadius: '8px',
      marginRight: '1rem',
      objectFit: 'cover',
    },
    name: {
      fontSize: '1.8rem',
      color: '#2d3748',
      margin: 0,
    },
    description: {
      fontSize: '1rem',
      color: '#4a5568',
      lineHeight: '1.5',
      marginBottom: '1.5rem',
    },
    linkedinButton: {
      display: 'inline-flex',
      alignItems: 'center',
      backgroundColor: '#0077b5',
      color: 'white',
      padding: '0.5rem 1rem',
      borderRadius: '6px',
      textDecoration: 'none',
      fontWeight: 'bold',
      transition: 'background-color 0.3s ease',
    },
    icon: {
      marginRight: '0.5rem',
    },
  };
  // const PodcastEpisode = ({ title, description, guest }) => {
  //   return (
  //     <div className="podcast-episode">
  //       <h2>{title}</h2>
  //       <p>{description}</p>
  //       <GuestInfo {...guest} />
  //     </div>
  //   );
  // };



  const EpisodesList = ({ episodes }) => {
    const sortedEpisodes = episodes.sort((a, b) => parseInt(a.episode_number, 10) - parseInt(b.episode_number, 10));
    let scheduledDate = '2024-03-24'
    return (
      <>

        {sortedEpisodes.map(episode => (
          <div key={episode.episode_number} className='podplayer-episode-container' >
            <h2 className='podplayer-episode-title'> {episode?.title}</h2>
            <p className='podplayer-episode-description'>{episode.description}</p>
            <div className='audio-player-container'>
              <EpisodeContent episode={episode} />
            </div>
          </div>
        ))}
        <div className='podplayer-button-container'>
          {/* <AuthorSection
            author={"Prashant Agrawal"}
            author_imageUrl={"https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id%3Duser789/mrp.jpeg"}
            linkedinUrl={"https://www.linkedin.com/in/pka3300/"}
            otherBlogTitle={"test"}
          /> */}
          <PodcastGuest
            name="Prashant Agrawal"
            description="A Founder, Strategist, and Business Developer, Prashant is passionate advocate for entrepreneurship. His curiosity-driven approach helps founders navigate critical challenges, embodying the belief that 'Clarity precedes Success' in every strategic decision. He is a firestarter himself."
            imageUrl="https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id%3Duser789/mrp.jpeg"
            linkedinUrl="https://www.linkedin.com/in/pka3300/"
          />
          {/* <GuestInfo name="test" blurb="test" linkedinUrl="test" imageUrl="/linkedin-icon.png" /> */}
        </div>
        <div className='podplayer-button-container'><button className="buttonFlat" onClick={() => navigateWithParams('/start', {})}>Create Your Own Pod Potion</button>
          <ShareButton link={window.location.href} />
          {!podcastConfig?.purchase_status && podcastConfig?.user_owned && <button type="submit" disabled={loadingState !== LOADING_STATES.Ready} className="buttonFilled start-call-to-action buttonSpace" onClick={showDrawer}>
            Purchase
          </button>}</div>

        {!purchased &&
          <Drawer
            title={`Purchase "${podcastConfig?.title}"`}
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
          >
            {options && <Elements stripe={stripePromise} options={options}>
              <Checkout episodes={episodes} ></Checkout>
            </Elements>}
          </Drawer>}

      </>
    );
  };

  return (
    <>
      <div className="podplayer-page-container">
        <div className="podplayer-input-container">
          <div className='podplayer-logo-container'>
            <Logo />

          </div>
          {podcastConfig?.image_url &&
            <div className="podplayer-image-container">
              <img src={podcastConfig?.image_url} alt={podcastConfig?.description} />
            </div>}
          <h1 className="podplayer-input-title">{podcastConfig?.title ? podcastConfig.title : "Brewing your Pod Potion"}
            <ShareButton link={window.location.href} />
          </h1>

          {/* {user && podcastConfig && podcastConfig?.purchase_status && <div>{publicChecked === '' ? '' : 'Private'}<Switch
            checked={publicChecked}
            onClick={() => handlePublicChange(podcastConfig.PK)}
            inputProps={{ 'aria-label': 'controlled' }}
          />{publicChecked === '' ? 'loading' : 'Public'}</div>} */}
          {podcastConfig?.prompt && <div className='podplayer-prompt-container' >
            <Collapse
              items={[
                {
                  key: 'prompt',
                  label: 'Creation Prompt',
                  children: <p>"{podcastConfig?.prompt}"</p>,
                },
              ]}
            />
            <div className='podplayer-button-container'><button className="buttonFlat" disabled={loadingState !== LOADING_STATES.Ready} onClick={() => navigateWithParams('/start', {})}>Create New</button>

              {!podcastConfig?.purchase_status && podcastConfig?.user_owned && <button type="submit" disabled={loadingState !== LOADING_STATES.Ready} className="buttonFilled start-call-to-action buttonSpace" onClick={showDrawer}>
                Purchase
              </button>}</div>
          </div>}
          {!episodes ? <StateMessages state={loadingState} />
            : (episodes &&
              <EpisodesList episodes={episodes} />

            )}


        </div>
        <Drawer
          title="Feedback/Help"
          placement="bottom"
          height="auto"
          onClose={toggleFeedbackDrawer}
          open={isFeedbackOpen}
          className="player-feedback-drawer"
        >
          <Feedback context={`podpotionfeedback|id=${searchParams.get('id')}|user=${user?.username}`} />
        </Drawer>
      </div>

    </>
  );
};

export default PrashantPage;