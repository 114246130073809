import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getTranscript, checkUserAuthentication } from '../general/functions';
import { useUser } from '../components/UserContext';
import './Transcript.css'

const Transcript = () => {
    const [user, setUser] = useState('');
    const [transcript, setTranscript] = useState({ "title": "Loading Transcript", "prompt": "loading" });
    const [searchParams] = useSearchParams();
    const { userContext } = useUser();

    useEffect(() => {
        if (userContext) {
            setUser(userContext);
        } else {
            checkUserAuthentication(setUser);
        }
    }, []);

    // let transcript = {
    //     "title": "Loading Transcript",
    //     "prompt": "loading",
    // "description": "Dive into the dynamic world of podcasts with 'From Niche to Mainstream: The Podcasting Revolution'. Join us on a journey from the humble beginnings of audio blogging to the multi-billion-dollar industry it is today. This series is perfect for podcast enthusiasts and industry analysts eager to explore the evolution of podcasts, featuring expert insights, real-world examples, and an exploration of what the future holds for this transformative medium.",
    // "PK": "4a34f29e-c5e0-4081-b6b2-944b01459c52",
    // "episodes": [
    //     {
    //         "title": "Welcome to the Revolution (Trailer)",
    //         "description": "A tantalizing preview into the world of podcasting's explosive journey from niche hobby to global phenomenon.",
    //         "episode_number": 0,
    //         "messages": [
    //             {
    //                 "host": "Ever wondered how a simple concept of audio storytelling transformed into a mammoth industry? Welcome to 'From Niche to Mainstream: The Podcasting Revolution'."
    //             },
    //             {
    //                 "cohost": "It's incredible to think about, isn't it? Just a few years ago, podcasts seemed like a small, specialized interest. Now, they're everywhere! I'm curious about what sparked this dramatic shift. Was there a particular moment or innovation that catapulted podcasts from being niche to mainstream?"
    //             },
    //             {
    //                 "host": "The surge in podcast popularity didn't hinge on a single moment but was propelled by a concoction of technological advancements and cultural shifts. The proliferation of smartphones made podcasts easily accessible to millions, while improved internet speeds facilitated seamless streaming. Also, the entry of key players like Apple and Spotify into the podcasting world significantly reduced the entry barriers for both creators and listeners, leading to an immense diversification of content. This evolution from niche interest to mainstream media wasn't overnight magic; it was a gradual build-up fueled by accessibility, variety, and changing consumer behavior towards digital content consumption. Join us on this journey as we untangle this web further in upcoming episodes!"
    //             }
    //         ]
    //     }
    // ]
    // }

    const loadTranscript = async () => {
        console.log('transcript', searchParams.get('id'), searchParams.get('episode'), user)
        if (user) {
            const t = await getTranscript(searchParams.get('id'), searchParams.get('episode'), user?.signInUserSession?.accessToken?.jwtToken);
            setTranscript(t);
        }
        // const transcript = await getTranscript(pk, sk, user?.signInUserSession?.accessToken?.jwtToken);
        // console.log('transcript', transcript)
    };

    useEffect(() => {
        loadTranscript();
    }, [user]);

    const { title, prompt, episodes } = transcript;



    return (

        <div className="transcript-view">
            {transcript?.title &&
                <><h1 className="transcript-title">{title}</h1>
                    <div className='transcript-prompt-container'>
                    <p className="transcript-prompt">"{prompt}"</p>
                    </div>
                    <div className="transcript-episodes">
                        {episodes && episodes.map((episode, index) => (
                            <div key={index} className="transcript-episode">
                                <h2 className="transcript-episode-title">EP {episode.episode_number}: {episode.title}</h2>
                                <p className="transcript-episode-description">{episode.description}</p>
                                <div className="transcript-messages">
                                    {episode.messages.map((message, msgIndex) => (
                                        <div key={msgIndex} className="transcript-message">
                                            {Object.entries(message).map(([role, text], roleIndex) => (
                                                <p key={roleIndex} className={`transcript-message-text ${role}`}>
                                                    <strong>{role}: </strong>{text}
                                                </p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </>}
        </div>
    );
};

export default Transcript;
