import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Logo from './Logo';


const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navigation() {

    // useEffect(() => {
    //     if (userContext) {
    //       setUser(userContext);
    //     } else {
    //       checkUserAuthentication(setUser);
    //     }
    //   }, []);


    const DiscordLink = () => {
        return (
            <a
                target="_blank"
                rel="noopener noreferrer"
                className="discord-link"
                href="https://discord.gg/youai-mindstudio"
                style={{ textDecoration: 'none', color: '#7289DA', display: 'flex', alignItems: 'center' }}
            >
                <svg
                    width="29"
                    height="23"
                    viewBox="0 0 29 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: '8px' }}
                >
                    <path
                        d="M24.1634 2.10124C22.3169 1.23732 20.3426 0.609463 18.2785 0.251953C18.025 0.710268 17.7288 1.32671 17.5246 1.81709C15.3304 1.48711 13.1564 1.48711 11.0025 1.81709C10.7984 1.32671 10.4955 0.710268 10.2397 0.251953C8.17338 0.609463 6.19677 1.23963 4.3503 2.10582C0.625937 7.73381 -0.383675 13.222 0.121132 18.6323C2.59132 20.477 4.98523 21.5975 7.33875 22.3308C7.91984 21.5311 8.4381 20.6809 8.88457 19.7849C8.03425 19.4618 7.21984 19.0631 6.45031 18.6002C6.65446 18.449 6.85415 18.2908 7.04708 18.1281C11.7407 20.3234 16.8403 20.3234 21.4778 18.1281C21.673 18.2908 21.8727 18.449 22.0746 18.6002C21.3028 19.0654 20.4862 19.4641 19.6359 19.7872C20.0823 20.6809 20.5984 21.5334 21.1817 22.3331C23.5375 21.5998 25.9336 20.4793 28.4038 18.6323C28.9961 12.3604 27.3919 6.9226 24.1634 2.10124ZM9.524 15.305C8.11504 15.305 6.95957 13.9897 6.95957 12.3879C6.95957 10.7861 8.09036 9.46849 9.524 9.46849C10.9577 9.46849 12.1131 10.7838 12.0884 12.3879C12.0907 13.9897 10.9577 15.305 9.524 15.305ZM19.0009 15.305C17.592 15.305 16.4365 13.9897 16.4365 12.3879C16.4365 10.7861 17.5672 9.46849 19.0009 9.46849C20.4346 9.46849 21.59 10.7838 21.5653 12.3879C21.5653 13.9897 20.4346 15.305 19.0009 15.305Z"
                        fill="#7289DA"
                    />
                </svg>
                Discord
            </a>
        );
    };

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" color="primary2" elevation={0}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Logo />



                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, display: 'block' }}
                            >
                                {page}
                            </Button>

                        ))}
                    </Box> 
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>

                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navigation;