import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCustomNavigate } from '../general/functions';
import { Auth } from 'aws-amplify';
import SignInForm from './SignInForm';


const ProtectedRoute = ({ element: Element, source }) => {
  const customNavigate = useCustomNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log('loc', decodeURIComponent(location.pathname))
  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
        if (source) {
          customNavigate(source, { 'protected_path': decodeURIComponent(location.pathname) });
        } else {
          customNavigate('/login', { 'protected_path': decodeURIComponent(location.pathname) });
        }
      }
    };

    checkAuthState();
  }, [navigate, location]);

  return isAuthenticated ? Element : <SignInForm />;
};

export default ProtectedRoute;
