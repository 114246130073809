import React from 'react';
import { Box, Button, Card, CardContent, Container, Grid, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const billingTheme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    },
    secondary: {
      main: '#f50057'
    }
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h5: {
      fontWeight: 500
    }
  }
});

const Account = () => (
  <ThemeProvider theme={billingTheme}>
    <Container>
      <Typography variant="h5" gutterBottom>
        Current Plan
      </Typography>
      <Card>
        <CardContent>
          <Typography variant="h6">Premium Plan - $29.99/month</Typography>
          <Typography variant="body2">Key features of the premium plan.</Typography>
          <Button variant="contained" color="secondary" sx={{ mt: 2 }}>
            Change Plan
          </Button>
        </CardContent>
      </Card>
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        Update Payment Method
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField label="Card Number" fullWidth />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField label="Expiry Date" fullWidth />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField label="CVV" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Billing Address" fullWidth />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button variant="contained" color="primary">
          Save Payment Method
        </Button>
      </Box>
    </Container>
  </ThemeProvider>
);

export default Account;
