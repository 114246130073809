import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <Link to="/" className="logo" style={{ textDecoration: 'none' }}>
            <img src="./logo512.png" alt="Pod Potion Logo" />
            <span className="logoText">Pod Potion</span>
        </Link>
    );
}

export default Logo;