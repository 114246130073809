import React from 'react';
import './TermsOfService.css';



const PrivacyPolicy = () => {
  const termsData = [
    {
      "title": "Introduction",
      "content": [
        "Thanks for using Pod Potion. We take your privacy seriously. This Privacy Policy is here to tell you exactly what information we collect when you use our website and services, how we use it, and how we keep it safe. It also explains your rights and how you can control your personal information. This policy is for everyone who uses our platform."
      ]
    },
    {
      "title": "Information We Collect",
      "content": [
        "This section should detail the types of information your platform collects from users. Given your earlier description, you likely collect personal information for account creation, payment processing, service delivery, and analytics.",
        "We collect some info from you to make Pod Potion work. This includes things like your email address when you sign up, payment details when you buy something, and the prompts you give us to create your podcasts."
      ]
    },
    {
      "title": "How We Use Your Information",
      "content": [
        "Here's what we do with the information you share with us:",
        {
          "To Serve You": "We need your email and payment details to set up your Pod Potion account and process your orders. That's how we keep things running smoothly for you."
        },
        {
          "Custom Podcasts": "Your creative prompts are what we use to produce those unique podcasts you love. It's all about bringing your ideas to life."
        },
        {
          "Making Things Better": "We're always looking to improve Pod Potion. By seeing how you use our service, we get valuable insights that help us enhance your experience."
        },
        {
          "Here for You": "Got a question or facing an issue? Your information helps us provide the support you need, quickly and efficiently."
        },
        "And here's our promise to you: You're not the product here. You're our valued customer. We're in the business of selling podcast services, not your personal information. So rest assured, your email, payment info, IP address, name, and other personal details are not for sale. We use your information to serve you, not to sell to others."
      ]
    },
    {
      "title": "Information Sharing and Disclosure",
      "content": [
        "We're careful with your info. Here's when and why we might share it:",
        {
          "With Service Providers": "We team up with other companies for services like processing your payments and sending emails on our behalf. They get access to your info just to do these jobs for us, and they're not allowed to use it for anything else."
        },
        {
          "For Legal Stuff": "If the law says we have to share your info (like with a court order), we'll do it."
        },
        {
          "To Keep Things Safe": "If we think sharing your info is needed to stop illegal stuff, fraud, threats to safety, or to defend Pod Potion in legal battles, we'll do it."
        },
        {
          "If Big Changes Happen": "If Pod Potion ever merges with another company, gets bought, or sells parts of the business, your info might be part of the deal. We'll tell you if that happens and make sure you know your rights."
        },
        {
          "With Third-Party API Providers": "To create your podcasts, we might share your prompts or other data with third-party services (some examples include AI services OpenAI, Anthropic, audio conversion tools like Eleven Labs, or other necessary services). We choose partners who prioritize privacy, and they're only allowed to use your data to help us provide our services."
        },
        "And we'll say it again – we don't sell your personal info."
      ]
    },
    {
      "title": "Your Rights",
      "content": [
        "You have control over your information with us. If you want to see what data we've got on you, make any changes to it, or if you decide you want us to remove it from our records entirely, just let us know. We're here to help and make sure you feel comfortable with how your data is handled."
      ]
    },
    {
      "title": "Data Security",
      "content": [
        "We take the security of your information very seriously. We've put safeguards in place to protect your data from being accessed by someone who shouldn't have it, lost, or misused. While no system is perfect, we're constantly working to keep your data safe."
      ]
    },
    {
      "title": "Cookies and Tracking Technologies",
      "content": [
        "On Pod Potion, we use cookies—small text files stored on your device—and other tracking technologies like Google Analytics to gather information about how our site is used and to make sure everything runs smoothly for you.",
        {
          "Why We Use Cookies": [
            "To Improve Your Experience: Cookies help remember your preferences and settings, making your visits more tailored and pleasant.",
            "For Analytics: Tools like Google Analytics use cookies to understand how visitors interact with our site, which helps us improve content, layout, and functionality."
          ]
        },
        {
          "Your Choices": "You have control over cookies. Most web browsers allow you to view, manage, and delete cookies through their settings. Remember, if you choose to block cookies, some parts of our site might not work as intended."
        },
        "Questions?: If you have any questions about how we use cookies or want more details, feel free to email us. We're here to clarify and assist.",
        "Please note: As of now, we don't have a cookie notice on our site, but we're committed to transparency about our use of cookies and tracking technologies."
      ]
    },
    {
      "title": "International Data Transfers",
      "content": [
        "Because Pod Potion operates globally, we may need to transfer your information to countries outside your own, especially if our servers or third-party service providers are located internationally. We take steps to ensure that your data receives the same level of protection as if it remained within your country, including adhering to data protection laws and using standard contractual clauses approved by data protection authorities. Your use of our services indicates your consent to such international transfers."
      ]
    },
    {
      "title": "Children's Privacy",
      "content": [
        "Pod Potion is not directed towards children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently received personal information from a child under 13, we will delete such information from our records. If you are a parent or guardian and believe that your child under 13 has provided us with personal information, please contact us so we can take appropriate actions."
      ]
    },
    {
      "title": "Third-Party Websites and Services",
      "content": [
        "Our platform may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our platform, we encourage you to read the privacy policy of every website you visit."
      ]
    },
    {
      "title": "User-Generated Content",
      "content": [
        "Our platform may allow you to create and share content, such as comments or podcast prompts. While some of this content may be visible to other users or the public, we strongly recommend against sharing any personal data within your podcast prompts or any other user-generated content. Anything you share can be read, collected, and used by others, so it's important to be mindful of your privacy."
      ]
    },
    {
      "title": "Privacy Rights",

      "content": [
        "As a user of Pod Potion, you are afforded specific rights regarding your personal information, consistent with applicable data protection laws:",
        {"Right to Know, Access, and Data Portability": "You have the right to be fully informed about the collection and use of your personal data, including the right to know what personal information is gathered, to access specific details of the information held about you, and to receive a copy of your data in a common format. This empowers you to understand, and potentially reuse, your personal data across different services, ensuring transparency and control over your information."},
        {"Right to Deletion": "You have the right to request the deletion of your personal information that Pod Potion has collected from you, subject to certain exceptions as allowed by law."},
        "To exercise your these rights, you may submit a verifiable consumer request to us using the contact details provided in the 'Contact Us' section of this policy. We will verify your request using the information associated with your account, including email address. Government identification may be required. Consumers can also designate an authorized agent to exercise these rights on their behalf."
      ]
    },
    {
      "title": "Changes to This Privacy Policy",
      "content": [
        "Pod Potion is always evolving, and so is this Privacy Policy. If we make changes, especially ones that significantly affect your rights, we'll make sure to let you know by posting an update on our website or dropping you an email."
      ]
    },


    {
      "title": "Contact Us",
      "content": [
        "If you have any questions or concerns regarding this Privacy Policy, or if you wish to exercise your rights under the CCPA or any other data protection laws that may apply, please do not hesitate to get in touch with us. Our dedicated team is here to assist you with any inquiries or requests related to your personal information and privacy.",
        "Email: podpotion@funcannyai.com",
        "Your privacy is of utmost importance to us at Pod Potion. By engaging with our services, you acknowledge and consent to the practices described in this Privacy Policy. If you do not agree with any term in this policy, please refrain from using our services."
      ]
    }


  ]
  const renderContentItem = (item, index) => {
    if (typeof item === 'string') {
      return <p key={index} className="terms-content">{item}</p>;
    } else if (Array.isArray(item) && item[0] === "link") {
      return <a key={index} href={item[1]} target="_blank" rel="noopener noreferrer" className="terms-link">{item[2]}</a>;
    } else if (typeof item === 'object') {
      return (
        <ul key={index} className="terms-bullet-list">
          {Object.entries(item).map(([key, value], idx) => (
            <li key={idx} className="terms-bullet-item"><strong>{key}:</strong> {value}</li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div className="terms-container">
      <h1 className="terms-page-title">Privacy Policy</h1>
      <h2 className="terms-effective-date">Effective Date: Mar 1, 2024</h2> {/* Adjust the date as needed */}

      {termsData.map((section, index) => (
        <div key={index} className="terms-section">
          <h2 className="terms-title">{section.title}</h2>
          {section.content.map((item, pIndex) => renderContentItem(item, pIndex))}
        </div>
      ))}
    </div>
  );
};

export default PrivacyPolicy;
