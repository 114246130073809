import React, { useEffect, useRef, useState, memo } from 'react';

const AudioPlayer = memo(({ url }) => {
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(url);

  const handleAudioClick = () => {
    console.log('Audio clicked');
  };

  useEffect(() => {
    const savedTime = localStorage.getItem(`audioTime-${audioUrl}`);
    const wasPlaying = localStorage.getItem(`audioPlaying-${audioUrl}`) === 'true';

    if (audioRef.current) {
      if (savedTime !== null) {
        audioRef.current.currentTime = parseFloat(savedTime);
      }

      if (wasPlaying) {
        audioRef.current.play().catch(error => console.error('Error attempting to play audio:', error));
      }
    }

    const updateTime = () => {
      if (audioRef.current) {
        localStorage.setItem(`audioTime-${audioUrl}`, audioRef.current.currentTime);
      }
    };

    const updateState = () => {
      if (audioRef.current) {
        localStorage.setItem(`audioPlaying-${audioUrl}`, audioRef.current.paused ? 'false' : 'true');
      }
    };

    audioRef.current.addEventListener('timeupdate', updateTime);
    audioRef.current.addEventListener('play', updateState);
    audioRef.current.addEventListener('pause', updateState);

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', updateTime);
        audioRef.current.removeEventListener('play', updateState);
        audioRef.current.removeEventListener('pause', updateState);
      }
    };
  }, [audioUrl]);

  useEffect(() => {
    setAudioUrl(url);
  }, [url]);

  return (
    <audio
      ref={audioRef}
      className="audio-player"
      controls
      src={audioUrl}
      onClick={handleAudioClick}
    >
      Your browser does not support the audio element.
    </audio>
  );
});

export default AudioPlayer;
