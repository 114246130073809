import React, { useState, useEffect } from 'react';
import './CostCalculator.css';

const CostCalculator = ({setNumEpisodes, setMinutesPerEpisode, numEpisodes, minutesPerEpisode}) => {

    const [totalMinutes, setTotalMinutes] = useState(0);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const costPerMinute = 0.4; // Cost rate per minute

    useEffect(() => {
        const total = numEpisodes * 5;
        setTotalMinutes(total);
        setEstimatedCost(total * costPerMinute);
    }, [numEpisodes, minutesPerEpisode]);

    return (
        <div className="cost-calculator">
            <div className="slider-container">
                <label className="slider-label">Number of Episodes</label>
                <div className="slider-with-labels">
                    <span className="slider-min">1</span>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={numEpisodes}
                        onChange={(e) => setNumEpisodes(Number(e.target.value))}
                        className="slider"
                        list="tickmarks"
                    />
                    <span className="slider-max">10</span>
                </div>
                <datalist id="tickmarks">
                    {[...Array(10).keys()].map(x => <option key={x + 1} value={x + 1} />)}
                </datalist>
            </div>
            {/* <div className="slider-container">
                <label className="slider-label">Minutes Per Episode</label>
                <div className="slider-with-labels">
                    <span className="slider-min">3</span>
                    <input
                        type="range"
                        min="3"
                        max="10"
                        value={minutesPerEpisode}
                        onChange={(e) => setMinutesPerEpisode(Number(e.target.value))}
                        className="slider"
                        list="minute-marks"
                    />
                    <span className="slider-max">10</span>
                </div>
                <datalist id="minute-marks">
                    {[...Array(8).keys()].map(x => <option key={x + 3} value={x + 3} />)}
                </datalist>
            </div> */}
            <div className="display-label">Episodes: {numEpisodes}</div>
            <div className="display-label estimated-cost">Total Audio: {totalMinutes}+ minutes</div>
            <div className="display-label estimated-cost">Cost: ${estimatedCost.toFixed(2)}</div>
        </div>
    );
};

export default CostCalculator;