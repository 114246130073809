


import React from 'react';
import { Card, CardContent, Typography, Button, Box, MobileStepper, useTheme } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { carouselContent } from '../general/samplesLandingCarousel';
import './Carousel.css'


function ContentCarousel() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = carouselContent.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const goToSample = (pk) => {
        console.log('go to sample')
        window.open(`https://${process.env.REACT_APP_DOMAIN}/podplayerv2?id=${pk}`, '_blank', 'noopener,noreferrer');
    };

    return (
        <Box className='carousel-box' sx={{ maxWidth: 400, flexGrow: 1, margin: 'auto' }}>
            <div className="carousel-hear-samples">
                <span className="overline">
                    <span >Hear Samples</span>
                    <br></br>
                </span>
            </div>
            <Card className='carousel-card' raised>
                <CardContent>
                    <Typography variant="h5" component="div">
                        {carouselContent[activeStep].heading}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {carouselContent[activeStep].subheading}
                    </Typography>

                    <Typography className="carousel-prompt">
                        "{carouselContent[activeStep].prompt}"
                        <Typography className="carousel-prompt-user"> -prompt for "{carouselContent[activeStep].title}"</Typography>
                    </Typography>

                    <button className='buttonFlat' size="small" onClick={() => goToSample(carouselContent[activeStep].pk)}>
                        Listen
                    </button>
                </CardContent>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}

                        </Button>
                    }
                />
            </Card>

        </Box>
    );
}

export default ContentCarousel;
