import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Add as AddIcon, ViewList as ViewListIcon, AccountCircle as AccountCircleIcon, Settings as SettingsIcon } from '@mui/icons-material';
// import { checkUserAuthentication, loadProfile } from '../general/functions';
import Account from '../components/Account';
import { useUser } from '../components/UserContext';
import { styled, useTheme } from '@mui/material/styles';
import Logo from '../components/Logo';
import SimpleStart from './SimpleStart';
import Projects from '../components/Projects';
import Profile from '../components/Profile';
// import GetUser from '../components/GetUser';
import TempPodcastPlayer from '../components/Temp';
import { UserProvider } from '../components/GetUser';
import Create from '../components/Create';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBarStyled = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Dashboard = () => {
    // const { user, profile, loading, error } = GetUser();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    // const [user, setUser] = useState('');
    const { userContext } = useUser();
    // const [profile, setProfile] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [playerOpen, setPlayerOpen] = useState(false);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // useEffect(() => {
    //     if (userContext) {
    //         setUser(userContext);
    //         console.log('userContext', userContext)
    //     } else {
    //         checkUserAuthentication(setUser);
    //     }
    // }, []);

    // useEffect(() => {
    //     if (user?.signInUserSession?.accessToken?.jwtToken) {
    //         loadProfile(user?.signInUserSession?.accessToken?.jwtToken, setProfile);
    //     }
    // }, [user]);

    // useEffect(() => {
    //     console.log('profile', profile)
    // }, [profile]);


    const handleListItemClick = (index) => {
        setSelectedIndex(index);
    };
    const renderContent = () => {
        switch (selectedIndex) {
            case 0:
                return <>
                <Create/>
                    {/* <SimpleStart /> */}
                    {/* <TempPodcastPlayer
                        open={true}
                        onClose={loadProfile}
                        title={'Marketing Skills in Demand: A Beginner’s Guide'}
                        audioUrl={'https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id=9919991e-8091-70df-8d4f-eb99e3037337/key=4ff6b2b9-b7db-4727-852d-0c7d06546284%7Cf736491a-bdfb-4d08-a356-15942b4b6458_03b2f472-c771-40f5-ba1d-05a1e4aa40fc/Marketing%20Skills%20in%20Demand_%20A%20Beginner%E2%80%99s%20Guide_0.mp3'}
                        imageUrl={'https://podpotion-public-production.s3.us-west-1.amazonaws.com/podpotion/user_id=9919991e-8091-70df-8d4f-eb99e3037337/key=1e6fedfa-b84a-46e5-b216-9c38d6b44238_config/image.jpg'}
                    /> */}
                </>;
            case 1:
                return <Projects />;
            case 2:
                return (
                    <Profile />
                );
            case 3:
                return <Account />;
            default:
                return <Typography paragraph>Select an option from the menu.</Typography>;
        }
    };

    return (
        <UserProvider>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBarStyled position="fixed" open={open}>
                    <Toolbar>

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Logo />
                    </Toolbar>
                </AppBarStyled>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {[
                            { text: 'Create', icon: <AddIcon /> },
                            { text: 'Projects', icon: <ViewListIcon /> },
                            { text: 'Profile', icon: <AccountCircleIcon /> },
                            { text: 'Account', icon: <SettingsIcon /> },
                        ].map((item, index) => (
                            <ListItem key={item.text}
                                onClick={() => handleListItemClick(index)}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                    {renderContent()}

                </Main>
            </Box>
        </UserProvider>
    );
}

export default Dashboard;